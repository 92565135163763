import styles from './EmailInput.module.css'

export default function EmailInput({email = "", setEmail = (value) => {}, label = "Электронная почта", id = "email", placeholder = "Введите электронную почту", auto_focus = false, error_message = "", reset_error_callback = () => {}})
{
    const emailHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину
        if(e.target.value.length > 30)
        {
            //Отрабатываем callback и выходим
            save_caret_callback();
            return;
        }

        //Обновляем состояние
        setEmail(e.target.value);
        //Сбрасываем ошибку
        reset_error_callback();
    }

    return (
        <div className={styles.formGroup}>
            <input id={id} name={id} className={error_message.length ? [styles.formInput, styles.error].join(' ') : styles.formInput} type="email" placeholder={placeholder} value={ email }
                 onChange={ emailHandler }></input>
            <label htmlFor={id} className={styles.formLabel}>{label}</label>
            <span className={styles.formClear} onClick={(e) => { setEmail(""); reset_error_callback(); }}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" strokeWidth="0.909137" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            {error_message.length !== 0 &&
            <div className={styles.formError}>{error_message}</div>
            }
        </div>
    );
}

import styles from './Restore.module.css'
import { useState, useEffect } from 'react';
import PreLoader from './../../PreLoader';
import UnloggedHeader from './../../Headers/UnloggedHeader';
import PhoneInput from './../../Inputs/PhoneInput';
import PasswordInput from './../../Inputs/PasswordInput';
import CodeInput from './../../Inputs/CodeInput';
import Notice from './../../Notice';
import auth_image from '../../assets/img/auth-image.jpg'
import ReCAPTCHA from "react-google-recaptcha";

//Перечисление секций, между которыми переключается страница
const Sections = {
	PhoneEntring: 1,
	CodeAndPasswordEntring: 2
}

export default function Restore()
{
    //Активная секция страницы
    const [section, setSection] = useState(Sections.PhoneEntring);

    //Номер телефона из поля ввода
    const [phone, setPhone] = useState("");
    //Ошибка для вывода в форме ввода номера телефона
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

    //Новый пароль из поля ввода
    const [newPassword, setNewPassword] = useState("");
    //Ошибка для вывода в форме ввода нового пароля
    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
    //Повтор нового пароля из поля ввода
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    //Ошибка для вывода в форме ввода повтора нового пароля
    const [newPasswordRepeatErrorMessage, setNewPasswordRepeatErrorMessage] = useState("");

    //Код из поля ввода
    const [code, setCode] = useState("");
    //Ошибка для вывода в форме ввода кода
    const [codeErrorMessage, setCodeErrorMessage] = useState("");

    //Список ошибок для вывода в модальном окне
    const [errorMessages, setErrorMessages] = useState([]);
    //Флаг загрузки данных (необходимости показа прелоадера)
    const [loading, setLoading] = useState(false);
    //Токен капчи
    const [capchaToken, setCapchaToken] = useState(null);

    /* eslint-disable */
    useEffect(() => {
        document.title = "Восстановление пароля - Здоровый ребенок";

        //Запрашиваем статус
        if(localStorage.getItem('accessToken'))
        {
            //Включаем анимацию загрузки
            setLoading(true);

            fetch('/api/getcabuserinfo', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //Если пользователь уже авторизован, перенаправляем его на главную
                    if(data.result)
                        window.location = "/";
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }, []);
    /* eslint-enable */

    const validatePhoneNum = (phone) => {
        //Проверяем номер телефона на длину
        if(phone.length !== 10)
        {
            setPhoneErrorMessage("Неверная длина номера телефона");
            return false;
        }

        //Проверяем номер телефона на наличие нечисловых символов
        for(let symbol of phone)
        {
            if(symbol < '0' || symbol > '9')
            {
                setPhoneErrorMessage("Номер телефона содержит неопознанные символы");
                return false;
            }
        }

        return true;
    }

    const validateCapcha = () => {
        //Проверяем, введена ли капча
        if(capchaToken === null)
        {
            if(errorMessages.indexOf("Вы не прошли проверку на робота") === -1)
                setErrorMessages([...errorMessages, "Вы не прошли проверку на робота"]);
            return false;
        }

        return true;
    }

    const submitPhoneHandler = (e) => {
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validatePhoneNum(phone))
            data_ok = false;
        if(section === Sections.PhoneEntring && !validateCapcha())
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
        {
            //Включаем анимацию загрузки
            setLoading(true);

            //Отправляем данные на сервер
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "userLogin": phone })
            };
            fetch('/api/RestorePassword', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(data.result === false)
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf(data.body.errorAns) === -1)
                            setErrorMessages([...errorMessages, data.body.errorAns]);
                    }
                    //Иначе переходим к следующей секции
                    else
                    {
                        setErrorMessages([]);
                        setSection(Sections.CodeAndPasswordEntring);
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);
                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }

    const repeatCall = (e) => {
        //Включаем анимацию загрузки
        setLoading(true);

        //Отправляем данные на сервер
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "userLogin": phone })
        };
        fetch('/api/RepeatCall', request)
            .then(response => response)
            .then(data => {
                //Выключаем анимацию загрузки
                setLoading(false);
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                setLoading(false);
                //Устанавливаем ошибку
                if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                    setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
            });
    }

    const validateCode = (code) => {
        //Проверяем код на длину
        if(code.length !== 4)
        {
            setCodeErrorMessage("Код не заполнен");
            return false;
        }

        return true;
    }

    const validatePassword = (new_password) => {
        //Проверяем пароль на длину
        if(new_password.length < 6)
        {
            setNewPasswordErrorMessage("Пароль содержит менее 6 символов");
            return false;
        }

        return true;
    }

    const validatePasswordRepeat = (new_password, new_password_repeat) => {
        //Проверяем пароли на совпадение
        if(new_password !== new_password_repeat)
        {
            setNewPasswordRepeatErrorMessage("Пароли не совпадают");
            return false;
        }

        return true;
    }

    const submitCodeHandler = (e) => {
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validateCode(code))
            data_ok = false;
        if(!validatePassword(newPassword))
            data_ok = false;
        if(!validatePasswordRepeat(newPassword, newPasswordRepeat))
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
        {
            //Включаем анимацию загрузки
            setLoading(true);

            //Отправляем данные на сервер
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "userLogin": phone, "userPassword": newPassword, "conformationCode": code })
            };
            fetch('/api/ApproveCabUserNumber', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(data.result === false)
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf(data.body.errorAns) === -1)
                            setErrorMessages([...errorMessages, data.body.errorAns]);
                    }
                    else
                    {
                        if(data.body.accessToken)
                        {
                            //Сохраняем полученный от сервера токен
                            localStorage.setItem('accessToken', data.body.accessToken);
                            //Перенаправляем пользователя на главную
                            window.location = "/";
                        }
                        else
                        {
                            //Устанавливаем ошибку и прекращаем обработку
                            if(errorMessages.indexOf("Сервер не вернул токен авторизации") === -1)
                                setErrorMessages([...errorMessages, "Сервер не вернул токен авторизации"]);
                            return;
                        }
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);
                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }

    if(section === Sections.PhoneEntring)
        return (
            <>
                {loading && <PreLoader />}
                <UnloggedHeader link="/login" />
                <main>
                    <div className={styles.fullScreen}>
                        <div className={[styles.container, styles.flexRow].join(' ')}>
                            <div className={styles.fullScreenContent}>
                                <form className={styles.restoreForm}>
                                    <p className={styles.formTitle}>
                                        Восстановление пароля
                                    </p>
                                    <p className={styles.formDescription}>
                                        Пожалуйста, укажите мобильный телефон, который вы использовали для входа на сайт
                                    </p>
                                    <PhoneInput phone={phone} setPhone={setPhone} error_message={phoneErrorMessage} reset_error_callback={ () => setPhoneErrorMessage("") } auto_focus={true}/>
                                    <div className={styles.capcha}>
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY}
                                            onChange={ (token) => setCapchaToken(token) }/>
                                    </div>
                                    <input type="submit" className={styles.buttonPrimary} value="Далее" onClick={submitPhoneHandler}></input>
                                </form>
                                <p>
                                    Если Вы никогда не пользовались личным<br/>кабинетом, то <a href="/register">зарегистрируйтесь</a>
                                </p>
                            </div>
                            <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                        </div>
                    </div>
                </main>
                <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
            </>
        );
    else
        return (
            <>
                {loading && <PreLoader />}
                <UnloggedHeader link="/login" />
                <main>
                    <div className={styles.fullScreen}>
                        <div className={[styles.container, styles.flexRow].join(' ')}>
                            <div className={styles.fullScreenContent}>
                                <form className={styles.restoreForm}>
                                    <p className={styles.formTitle}>
                                        Восстановление пароля
                                    </p>
                                    <p className={styles.formDescription}>
                                        На Ваш номер телефона, указанный ранее, сейчас поступит звонок, и будет продиктован код
                                    </p>
                                    <CodeInput code={code} setCode={setCode} repeat_callback={repeatCall}
                                               error_message={codeErrorMessage} reset_error_callback={ () => setCodeErrorMessage("") }/>
                                    <PasswordInput password={newPassword} setPassword={setNewPassword} label="Новый пароль" id="new-password-input" auto_focus={false}
                                               error_message={newPasswordErrorMessage} reset_error_callback={ () => setNewPasswordErrorMessage("") }/>
                                    <PasswordInput password={newPasswordRepeat} setPassword={setNewPasswordRepeat} label="Повторите пароль" id="new-password-input-repeat" auto_focus={false}
                                               error_message={newPasswordRepeatErrorMessage} reset_error_callback={ () => setNewPasswordRepeatErrorMessage("") }/>
                                    <input type="submit" className={styles.buttonPrimary} value="Далее" onClick={submitCodeHandler}></input>
                                </form>
                                <p>
                                    <a href="/login">Вернуться ко входу в личный кабинет</a>
                                </p>
                            </div>
                            <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                        </div>
                    </div>
                </main>
                <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
            </>
        );
}

import styles from './PhoneInput.module.css'
import { useState, useEffect, useRef } from 'react';

export default function PhoneInput({phone = "", setPhone = (value) => {}, error_message = "", reset_error_callback = () => {}, auto_focus = false}) {
    //Позиция выделения (курсора)
    const [selectionStart, setSelectionStart] = useState(4);
    const [selectionEnd, setSelectionEnd] = useState(4);
    //Наличие фокуса на поле ввода
    const [inputFocused, setInputFocused] = useState(false);
    //Ссылка на поле ввода
    const textInput = useRef(null);

    //Автофокус при появлении компонента, если нужно
    useEffect(() => {
        if(auto_focus && textInput !== null)
            textInput.current.focus();
    }, []);

    //Восстановление позиции курсора при обновлении состояния
    /* eslint-disable */
    useEffect(() => {
        //Если элемент не в фокусе, выходим
        if(!inputFocused)
            return;

        //Если сохранённая позиция курсора слишком мала, сбрасываем её
        if(selectionStart < 4)
            setSelectionStart(4);
        if(selectionEnd < 4)
            setSelectionEnd(4);

        //Устанавливаем курсор в правильную позицию внутри элемента #phone
        let element = document.querySelector("#phone");
        if(element !== null)
            element.setSelectionRange(selectionStart < 4 ? 4 : selectionStart,
                                        selectionEnd < 4 ? 4 : selectionEnd);
    });
    /* eslint-enable */

    const formPhoneNumber = (str = phone) => {
        //Если телефон пуст и поле не в фокусе, возвращаем пустую строку
        if(str.length === 0 && !inputFocused)
            return "";

        //Подготавливаем шаблон номера
        let template = "+7 ";

        //Обрабатываем первую тройку символов
        template += "("
        for(let i = 0; i < 3; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем вторую тройку символов
        template += ") "
        for(let i = 3; i < 6; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем первую пару символов
        template += "-"
        for(let i = 6; i < 8; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем вторую пару символов
        template += "-"
        for(let i = 8; i < 10; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        return template;
    }

    const getLastDigitPosition = (str) => {
        let last_digit_position = -1;
        for(let i = str.length - 1; i >= 0; i--) {
          if (/\d/.test(str[i])) {
            last_digit_position = i;
            break;
          }
        }
        return last_digit_position;
    }

    //Обработчик изменения поля ввода
    const inputHandler = (e) => {
        //Получаем значение от пользователя
        let result = e.target.value;

        //Получаем позицию курсора
        let selection_start = e.target.selectionStart;
        let selection_end = e.target.selectionEnd;

        //Игнорируем ввод данных, если неизменяемую часть номера изменили
        if(result.length >= 2 && (result[0] !== '+' || result[1] !== '7'))
        {
            //Сохраняем позицию курсора и выходим
            setSelectionStart(selection_start);
            setSelectionEnd(selection_end);
            return;
        }

        //Игнорируем данные, длиннее ожидаемых
        if(result.replace(/\D/g, '').length > 11)
        {
            //Сохраняем позицию курсора и выходим
            setSelectionStart(selection_start);
            setSelectionEnd(selection_end);
            return;
        }

        //Удаляем начальные +7
        if(result.length >= 2 && result[0] === '+' && result[1] === '7')
            result = result.slice(2);

        //Удаляем нечисловые символы
        let result_copy = result;
        result = "";
        for(let i = 0; i < result_copy.length; i++)
        {
            if(result_copy[i] >= '0' && result_copy[i] <= '9')
                result = result + result_copy[i];
        }

        //Обрезаем лишние символы
        result = result.slice(0, 10);

        //Если данные вводятся, а не стираются
        if(e.target.value.length > formPhoneNumber().length)
        {
            //Пропускаем служебные символы
            if(selection_start === 13 || selection_start === 16)
                selection_start += 1;
            if(selection_end === 13 || selection_end === 16)
                selection_end += 1;
            if(selection_start === 3 || selection_start === 8)
                selection_start += 2;
            if(selection_end === 3 || selection_end === 8)
                selection_end += 2;

            //Переносим курсор к последнему числу в строке
            if(selection_start > getLastDigitPosition(formPhoneNumber(result)) + 1)
                selection_start = getLastDigitPosition(formPhoneNumber(result)) + 1;
            if(selection_end > getLastDigitPosition(formPhoneNumber(result)) + 1)
                selection_end = getLastDigitPosition(formPhoneNumber(result)) + 1;

            if(selection_start === 7)
                selection_start += 2;
            if(selection_end === 7)
                selection_end += 2;
            if(selection_start === 12 || selection_start === 15)
                selection_start += 1;
            if(selection_end === 12 || selection_end === 15)
                selection_end += 1;
        }
        else
        {
            if(selection_start === 9)
                selection_start -= 2;
            if(selection_end === 9)
                selection_end -= 2;
            if(selection_start === 13 || selection_start === 16)
                selection_start -= 1;
            if(selection_end === 13 || selection_end === 16)
                selection_end -= 1;
        }

        //Обновляем состояние
        setPhone(result);
        setSelectionStart(selection_start);
        setSelectionEnd(selection_end);
        //Вызываем callback для сброса ошибки
        reset_error_callback();
    }

    return (
        <div className={styles.formGroup} onClick={(e) => { if(textInput !== null) textInput.current.focus() }}>
            <input id="phone" name="phone" className={error_message.length ? [styles.formInput, styles.error].join(' ') : styles.formInput} type="text" placeholder="Введите" value={formPhoneNumber()}
                   onChange={inputHandler} ref={textInput} onFocus={(e) => setInputFocused(true)} onBlur={(e) => setInputFocused(false)}></input>
            <label htmlFor="phone" className={styles.formLabel}>Мобильный телефон</label>
            <span className={styles.formClear} onClick={(e) => {setPhone(""); setSelectionStart(4); setSelectionEnd(4); reset_error_callback();}}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" strokeWidth="0.909137" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            {error_message.length !== 0 &&
            <div className={styles.formError}>{error_message}</div>
            }
        </div>
    );

}

import React from 'react';
import './assets/css/main.css';
import { Link } from "react-router-dom";
import withRouter from './WithRouter';
import {Notice, initForms, initNotifications} from './assets/js/notice.js'
import { get_user_info, sendOnecSet, sendOnecSetByFamilyId, get_family_members_data } from './Mutual.js'
import logo from './assets/img/logo.png'
import person_detail from './assets/img/person-detail.png'
import vk_logo from './assets/img/vk.svg'
import tg_logo from './assets/img/telegram.svg'
import Profile from "./Profile/Profile"
import Tests from "./Tests/Tests"
import Visits from "./Visits/Visits"
import Main from "./Main/Main"
import Contacts from "./Contacts/Contacts"
import Appointment from "./Appointment/Appointment"
import Doctors from "./Doctors/Doctors"
import Service from "./Service/Service"
import Promotions from "./Promotions/Promotions"


//Перечисление секций, между которыми переключается окно выбора 1С
const OnecSections = {
	Selection: 1,
	Confirmation: 2
}

class Logged extends React.Component {
    constructor(props) {
        super(props);

        this.state = { cabuserinfo: {}, onec: {}, family_data: [], loading: false, error_messages: [], can_edit: null, open_you_can_edit_modal: false, open_choose_onec_modal: false, avatar_url: "",
                        notifications: [], selected_onec_index: -2, onec_modal_section: OnecSections.Selection };

        //Если пользователь не авторизован, перенаправляем его на страницу входа
        if(!localStorage.getItem('accessToken'))
            window.location = "/login";
    }

    componentDidMount() {
        //Запрашиваем и обрабатываем данные пользователя
        get_user_info((flag) => this.setState({can_edit: flag}), (e) => this.setState(e));

        //Включаем анимацию загрузки
        /* this.setState({ loading: true });

        //Счетчик обработанных запросов
        const requests_to_fetch = 1;
        let requests_processed = 0;

        //Запрашиваем оповещения для колокольчика
        fetch('/notifications', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ notifications: data.body });
            })
            .catch((error) => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            }); */
    }

    componentDidUpdate() {
        if(this.props.router.location.pathname === "/")
            document.title = "Главная - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/tests")
            document.title = "История анализов - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/visits")
            document.title = "История посещений - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/profile")
            document.title = "Профиль - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/promotions")
            document.title = "Специальные предложения - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/6")
            document.title = "6 - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/contacts")
            document.title = "Контакты - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/service")
            document.title = "Услуги - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/doctors")
            document.title = "Врачи - Здоровый ребенок";
        else if(this.props.router.location.pathname === "/appointment")
            document.title = "Запись - Здоровый ребенок";

        //При необходимости открываем модальное окно выбора 1С
        const form = document.getElementById("choose_onec");
        if(form)
        {
            if(this.state.open_choose_onec_modal) {
                if(!form.classList.contains('open'))
                    form.classList.add('open');
            }
            else {
                if(form.classList.contains('open'))
                    form.classList.remove('open');
            }
        }
    }

    render() {
        return (
            <>
                {this.state.loading &&
                <div class="pre-loader">
                    <div class="loader-spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                }

                <header>
                    <div class="container flex-row align-center">
                        <a href="/" class="logo">
                            <img src={logo}></img>
                        </a>
                        <div class="notifications new">
                            <div class="notifications-icon">
                                <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.38314 15.2917H3.12903C1.92303 15.2917 1.32004 15.2917 1.19317 15.198C1.05063 15.0928 1.01581 15.0307 1.00037 14.8543C0.986636 14.6972 1.35621 14.0924 2.09537 12.883C2.85854 11.6342 3.50656 9.81594 3.50656 7.24167C3.50656 5.81834 4.11236 4.45331 5.19069 3.44686C6.26903 2.44042 7.73156 1.875 9.25655 1.875C10.7816 1.875 12.2441 2.44042 13.3224 3.44686C14.4008 4.45331 15.0066 5.81834 15.0066 7.24167C15.0066 9.81594 15.6546 11.6342 16.4178 12.883C17.1569 14.0924 17.5265 14.6972 17.5128 14.8543C17.4974 15.0307 17.4625 15.0928 17.32 15.198C17.1931 15.2917 16.5901 15.2917 15.3841 15.2917H12.1316M6.38314 15.2917L6.38156 16.25C6.38156 17.8379 7.66879 19.125 9.25655 19.125C10.8444 19.125 12.1316 17.8379 12.1316 16.25V15.2917M6.38314 15.2917H12.1316" stroke="#000" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                {this.state.notifications.length !== 0 && <span class="notifications--count">{this.state.notifications.length}</span>}
                            </div>

                            <div class="notifications-content">
                                <div class="notifications-content--inner">
                                    <div class="notifications-content--close">
                                        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.33105 10.0914L10.6045 1.09137M1.33105 1L10.6045 9.99999" stroke="#D30D15" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div class="scroll">
                                        {this.state.notifications.map((data, index) =>
                                        <div class="notifications-content--item" key={index}>
                                            <p>{data.description}</p>
                                            <a href={data.link}>Перейти</a>
                                        </div>)}
                                        {this.state.notifications.length === 0 &&
                                        <div class="notifications-content--item">
                                            Уведомлений нет
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="person">
                            {this.state.avatar_url.length > 0 && <a href="/profile" class="person--photo" style={{backgroundImage: `url(${this.state.avatar_url})`}}></a>}
                            {this.state.avatar_url.length === 0 && <a href="/profile"><span class="person--photo-null">{((this.state.cabuserinfo && this.state.cabuserinfo.lname && this.state.cabuserinfo.lname.length > 0) ? this.state.cabuserinfo.lname.charAt(0) : 'X') +
                                                                           ((this.state.cabuserinfo && this.state.cabuserinfo.fname && this.state.cabuserinfo.fname.length > 0) ? this.state.cabuserinfo.fname.charAt(0) : 'Y')}</span></a>}
                            <a href="/profile" class="person--name">
                                {((this.state.cabuserinfo && this.state.cabuserinfo.lname) ? this.state.cabuserinfo.lname : "") + " " +
                                 ((this.state.cabuserinfo && this.state.cabuserinfo.fname) ? this.state.cabuserinfo.fname : "") + " " +
                                 ((this.state.cabuserinfo && this.state.cabuserinfo.sname) ? this.state.cabuserinfo.sname : "")}
                            </a>
                            <a href="#" class="person--logout" onClick={(e) => {localStorage.removeItem('accessToken'); this.props.router.navigate("/login");}}>
                                Выход
                            </a>
                        </div>
                        <a href="/appointment" class="button-primary">Записаться на приём</a>
                        <div class="mobile-menu-icon">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.0007C0 13.3563 0.696445 12.834 1.55555 12.834L26.4444 12.834C27.3036 12.834 28 13.3563 28 14.0007C28 14.645 27.3036 15.1673 26.4444 15.1673L1.55555 15.1673C0.696445 15.1673 0 14.645 0 14.0007Z" fill="#D30D15"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 4.66667C0 4.02233 0.696445 3.5 1.55555 3.5L26.4444 3.5C27.3036 3.5 28 4.02233 28 4.66667C28 5.311 27.3036 5.83333 26.4444 5.83333L1.55555 5.83333C0.696445 5.83333 0 5.311 0 4.66667Z" fill="#D30D15"/>
                                <path d="M0 23.3327C0 22.6884 0.696445 22.166 1.55555 22.166H26.4444C27.3036 22.166 28 22.6884 28 23.3327C28 23.977 27.3036 24.4993 26.4444 24.4993H1.55555C0.696445 24.4993 0 23.977 0 23.3327Z" fill="#D30D15"/>
                            </svg>
                        </div>
                    </div>
                </header>
                <div class="mobile-menu">
                    <div class="container mobile-menu--inner">
                        <div class="mobile-menu--top">
                            <div class="mobile-menu--logo">
                                <a href="/">
                                    <img src={logo}></img>
                                </a>
                            </div>
                            <div class="mobile-menu--close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.16699 22.8346L22.6159 1.38573M1.16699 1.16797L22.6159 22.6169" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                        <ul>
                            <li class={this.props.router.location.pathname === "/" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/"}>Главная</Link></li>
                            <li class={this.props.router.location.pathname === "/tests" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/tests"}>Мои анализы</Link></li>
                            <li class={this.props.router.location.pathname === "/visits" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/visits"}>Моя история</Link></li>
                            <li class={this.props.router.location.pathname === "/profile" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/profile"}>Профиль</Link></li>
                            <li class={this.props.router.location.pathname === "/promotions" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/promotions"}>Специальные предложения</Link></li>
                            <li class={this.props.router.location.pathname === "/contacts" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/contacts"}>Контакты</Link></li>
                            <li class={this.props.router.location.pathname === "/doctors" ? "selected" : ""} onClick={(e) => document.querySelector('.mobile-menu--close').click()}><Link to={"/doctors"}>Врачи</Link></li>
                        </ul>
                        <div class="mobile-menu--social">
                            <p class="mobile-menu--social__title">
                                Следите за нами в соцсетях
                            </p>
                            <div class="mobile-menu--social__list">
                                <a href="https://t.me/zr_medical">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.1766 11.9721C28.1846 11.9721 28.1952 11.9721 28.2058 11.9721C28.4834 11.9721 28.741 12.0584 28.9522 12.2072L28.9482 12.2045C29.1023 12.3386 29.2058 12.5272 29.2311 12.7397V12.7437C29.2576 12.9057 29.2722 13.0916 29.2722 13.2815C29.2722 13.3679 29.2696 13.4529 29.2629 13.5378V13.5259C28.9641 16.672 27.668 24.3015 27.0093 27.8234C26.7304 29.3147 26.1819 29.8141 25.6507 29.8619C24.4967 29.9695 23.6202 29.0996 22.502 28.3665C20.7517 27.2178 19.7623 26.5033 18.0637 25.3838C16.0996 24.0916 17.3732 23.3785 18.4914 22.2178C18.7849 21.9123 23.8725 17.2842 23.9721 16.8645C23.9774 16.8393 23.9801 16.8114 23.9801 16.7822C23.9801 16.6786 23.9416 16.5843 23.8792 16.5126C23.8101 16.4675 23.7238 16.4422 23.6335 16.4422C23.5737 16.4422 23.5166 16.4542 23.4635 16.4741L23.4661 16.4728C23.2908 16.5126 20.4949 18.3603 15.0784 22.0159C14.4874 22.4821 13.741 22.7769 12.9283 22.8114H12.9203C11.7689 22.672 10.7238 22.4157 9.73174 22.0505L9.83001 22.0823C8.58433 21.676 7.59495 21.4622 7.67995 20.7742C7.7251 20.4166 8.21824 20.0505 9.15936 19.676C14.9566 17.1501 18.8225 15.4852 20.757 14.6813C22.8911 13.5485 25.3652 12.6162 27.9668 12.012L28.1753 11.9708L28.1766 11.9721ZM19.9535 0C8.92829 0.0265604 0 8.97078 0 20C0 31.0452 8.95352 40 20 40C31.0465 40 40 31.0465 40 20C40 8.97078 31.0717 0.0265604 20.0491 0H20.0465C20.0155 0 19.9845 0 19.9535 0Z" fill="#D30D15"/>
                                    </svg>
                                </a>
                                <a href="https://vk.com/mczr_med">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20 0C8.95417 0 0 8.95417 0 20C0 31.0458 8.95417 40 20 40C31.0458 40 40 31.0458 40 20C40 8.95417 31.0458 0 20 0ZM27.6917 22.5646C27.6917 22.5646 29.4604 24.3104 29.8958 25.1208C29.9083 25.1375 29.9146 25.1542 29.9187 25.1625C30.0958 25.4604 30.1375 25.6917 30.05 25.8646C29.9042 26.1521 29.4042 26.2938 29.2333 26.3063H26.1083C25.8917 26.3063 25.4375 26.25 24.8875 25.8708C24.4646 25.575 24.0479 25.0896 23.6417 24.6167C23.0354 23.9125 22.5104 23.3042 21.9813 23.3042C21.914 23.3041 21.8472 23.3146 21.7833 23.3354C21.3833 23.4646 20.8708 24.0354 20.8708 25.5562C20.8708 26.0312 20.4958 26.3042 20.2313 26.3042H18.8C18.3125 26.3042 15.7729 26.1333 13.5229 23.7604C10.7687 20.8542 8.28958 15.025 8.26875 14.9708C8.1125 14.5937 8.43542 14.3917 8.7875 14.3917H11.9437C12.3646 14.3917 12.5021 14.6479 12.5979 14.875C12.7104 15.1396 13.1229 16.1917 13.8 17.375C14.8979 19.3042 15.5708 20.0875 16.1104 20.0875C16.2116 20.0863 16.311 20.0606 16.4 20.0125C17.1042 19.6208 16.9729 17.1104 16.9417 16.5896C16.9417 16.4917 16.9396 15.4667 16.5792 14.975C16.3208 14.6187 15.8812 14.4833 15.6146 14.4333C15.7225 14.2844 15.8647 14.1636 16.0292 14.0812C16.5125 13.8396 17.3833 13.8042 18.2479 13.8042H18.7292C19.6667 13.8167 19.9083 13.8771 20.2479 13.9625C20.9354 14.1271 20.95 14.5708 20.8896 16.0896C20.8708 16.5208 20.8521 17.0083 20.8521 17.5833C20.8521 17.7083 20.8458 17.8417 20.8458 17.9833C20.825 18.7562 20.8 19.6333 21.3458 19.9937C21.417 20.0384 21.4993 20.0622 21.5833 20.0625C21.7729 20.0625 22.3438 20.0625 23.8896 17.4104C24.3663 16.5568 24.7806 15.6697 25.1292 14.7562C25.1604 14.7021 25.2521 14.5354 25.3604 14.4708C25.4404 14.4301 25.529 14.4093 25.6187 14.4104H29.3292C29.7333 14.4104 30.0104 14.4708 30.0625 14.6271C30.1542 14.875 30.0458 15.6312 28.3521 17.925L27.5958 18.9229C26.0604 20.9354 26.0604 21.0375 27.6917 22.5646Z" fill="#D30D15"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <a href="https://t.me/ZR_info_bot" target="_blank" class="button-telegram">Чат-бот Telegram</a>
                        <a href="#" class="person--logout" onClick={(e) => {localStorage.removeItem('accessToken'); this.props.router.navigate("/login");}}>
                            Выход из профиля
                        </a>
                    </div>
                </div>
                <main>
                    <div class="content">
                        <div class="container flex">
                            <div class="side-bar">
                                <ul class="side-bar--menu">
                                    <li class={this.props.router.location.pathname === "/" ? "selected" : ""}><Link to={"/"}>Главная</Link></li>
                                    <li class={this.props.router.location.pathname === "/tests" ? "selected" : ""}><Link to={"/tests"}>Мои анализы</Link></li>
                                    <li class={this.props.router.location.pathname === "/visits" ? "selected" : ""}><Link to={"/visits"}>Моя история</Link></li>
                                    <li class={this.props.router.location.pathname === "/profile" ? "selected" : ""}><Link to={"/profile"}>Профиль</Link></li>
                                    <li class={this.props.router.location.pathname === "/promotions" ? "selected" : ""}><Link to={"/promotions"}>Специальные предложения</Link></li>
                                    <li class={this.props.router.location.pathname === "/contacts" ? "selected" : ""}><Link to={"/contacts"}>Контакты</Link></li>
                                    <li class={this.props.router.location.pathname === "/doctors" ? "selected" : ""}><Link to={"/doctors"}>Врачи</Link></li>
                                </ul>
                                <div class="side-bar--social">
                                    <p class="side-bar--social__title">
                                        Следите за нами в соцсетях
                                    </p>
                                    <div class="side-bar--social__list">
                                        <a href="https://vk.com/mczr_med" target="_blank">
                                            <img src={vk_logo}></img>
                                        </a>
                                        <a href="https://t.me/zr_medical" target="_blank">
                                            <img src={tg_logo}></img>
                                        </a>
                                    </div>
                                </div>
                                <a href="https://t.me/ZR_info_bot" target="_blank" class="button-telegram">Чат-бот Telegram</a>
                            </div>
                            <div class="side-content">
                                {this.props.router.location.pathname === "/" &&
                                <Main error_messages={this.state.error_messages} update_error_messages_callback={(errors) => this.setState({error_messages: errors})} family_data={this.state.family_data}/>
                                }
                                {this.props.router.location.pathname === "/tests" &&
                                <Tests error_messages={this.state.error_messages} update_error_messages_callback={(errors) => this.setState({error_messages: errors})} family_data={this.state.family_data}/>
                                }
                                {this.props.router.location.pathname === "/visits" &&
                                <Visits error_messages={this.state.error_messages} update_error_messages_callback={(errors) => this.setState({error_messages: errors})} family_data={this.state.family_data}/>
                                }
                                {this.props.router.location.pathname === "/profile" &&
                                <Profile cabuserinfo={this.state.cabuserinfo} onec={this.state.onec} family_data={this.state.family_data} error_messages={this.state.error_messages}
                                          update_error_messages_callback={(errors) => this.setState({error_messages: errors})} can_edit={this.state.can_edit}
                                          send_onec_set_callback={(onec, cabuserinfo) => sendOnecSet(onec, this.state.cabuserinfo, (errors) => this.setState({ error_messages: errors }), (e) => this.setState(e))}
                                          get_user_info_callback={() => get_user_info(() => {}, (e) => this.setState(e))}/>
                                }
                                {this.props.router.location.pathname === "/promotions" &&
                                <Promotions error_messages={this.state.error_messages} update_error_messages_callback={(errors) => this.setState({error_messages: errors})} router={this.props.router}/>
                                }
                                {this.props.router.location.pathname === "/6" &&
                                <div>Нет данных</div>
                                }
                                {this.props.router.location.pathname === "/contacts" &&
                                <Contacts error_messages={this.state.error_messages} update_error_messages_callback={(errors) => this.setState({error_messages: errors})}/>
                                }
                                {this.props.router.location.pathname === "/service" &&
                                <Service cabuserinfo={this.state.cabuserinfo} onec={this.state.onec} family_data={this.state.family_data} error_messages={this.state.error_messages}
                                                update_error_messages_callback={(errors) => this.setState({error_messages: errors})} router={this.props.router}/>
                                }
                                {this.props.router.location.pathname === "/doctors" &&
                                <Doctors cabuserinfo={this.state.cabuserinfo} onec={this.state.onec} family_data={this.state.family_data} error_messages={this.state.error_messages}
                                                update_error_messages_callback={(errors) => this.setState({error_messages: errors})} router={this.props.router}/>
                                }
                                {this.props.router.location.pathname === "/appointment" &&
                                <Appointment cabuserinfo={this.state.cabuserinfo} onec={this.state.onec} family_data={this.state.family_data} error_messages={this.state.error_messages}
                                                update_error_messages_callback={(errors) => this.setState({error_messages: errors})} router={this.props.router}/>
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <div id="choose_onec" class="modal manual">
                    {this.state.onec_modal_section === OnecSections.Selection &&
                    <div class="modal-content onec-modal toggleInFromRight">
                        <h2>К вашему личному кабинету привязано несколько профилей</h2>
                        <p class="onec-description">Выберите себя из представленных ниже профилей в качестве владельца личного кабинета или выберите вариант Меня нет в списке, если ваш профиль отсутствует.</p>
                        <div class="onec-variants">
                            {this.state.family_data.map((data, index) =>
                                <div key={index} style={(new Date().getFullYear() - data.birthdate.split('.')[2] > 15) ? {} : {"display": "none"}}>
                                    <input id={"its_me_" + index} name="its_me" type="radio" checked={this.state.selected_onec_index == index} data-index={index} onChange={(e) => this.setState({ selected_onec_index: e.currentTarget.dataset.index })}></input>
                                    <label htmlFor={"its_me_" + index}>Это я: {data.name} - {data.birthdate}</label>
                                </div>
                            )}
                            <div>
                                <input id="its_not_me" name="its_me" type="radio" checked={this.state.selected_onec_index == -1} data-index={-1} onChange={(e) => this.setState({ selected_onec_index: e.currentTarget.dataset.index })}></input>
                                <label htmlFor="its_not_me">Меня нет в списке</label>
                            </div>
                        </div>
                        <button type="button" class="button-primary button-onec" onClick={this.handleOnecChooseSection1}>Далее</button>
                    </div>
                    }
                    {this.state.onec_modal_section === OnecSections.Confirmation &&
                    <div class="modal-content onec-modal toggleInFromRight">
                        <p>Вы выбрали профиль <b>{this.state.family_data[this.state.selected_onec_index].name} - {this.state.family_data[this.state.selected_onec_index].birthdate}</b> в качестве владельца личного кабинета. Проверьте и подтвердите правильность выбора, в противном случае ваши данные могут быть утеряны.</p>
                        <div class="flex-row">
                            <button type="button" class="button-primary button-onec" onClick={this.handleOnecChooseSection2}>Далее</button>
                            <button type="button" class="button-lightgray button-darkergray button-onec" onClick={(e) => this.setState({ onec_modal_section: OnecSections.Selection })}>Отмена</button>
                        </div>
                    </div>
                    }
                </div>
                <div class="bottom-menu">
                    {this.props.router.location.pathname !== "/appointment" &&
                     this.props.router.location.pathname !== "/service" &&
                     this.props.router.location.pathname !== "/doctors" &&
                    <a href="/appointment" class="button-primary">Записаться на приём</a>}
                    <div class="container">
                        <div class="bottom-menu--list">
                            <a href="/">
                                <span class="icon">
                                    <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.33333 31H2.38889C1.62183 31 1 30.2363 1 29.2941V16.3533C1 15.9009 1.14633 15.4669 1.40679 15.147L12.5179 1.49966C13.0603 0.833446 13.9397 0.833446 14.4821 1.49966L25.5932 15.147C25.8536 15.4669 26 15.9009 26 16.3533V29.2941C26 30.2363 25.3782 31 24.6111 31H17.6667M9.33333 31H17.6667M9.33333 31V20.7645C9.33333 19.8223 9.95517 19.0585 10.7222 19.0585H16.2778C17.0449 19.0585 17.6667 19.8223 17.6667 20.7645V31" stroke="#D30D15" stroke-width="2" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                                Главная
                            </a>
                            <a href="/tests">
                                <span class="icon">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M29.7052 7.34167L22.6582 0.294697C22.2652 -0.0981313 21.6279 -0.0983333 21.2344 0.294697L18.8854 2.64369C18.6966 2.83247 18.5905 3.08854 18.5905 3.35551C18.5905 3.62248 18.6966 3.87854 18.8854 4.06732L19.3481 4.53005L1.26788 22.6104C0.450303 23.4278 0 24.5149 0 25.6711C0 26.8273 0.450303 27.9143 1.26788 28.732C2.08545 29.5495 3.17263 29.9999 4.32879 29.9999C5.48495 29.9999 6.57202 29.5495 7.3896 28.732L25.4699 10.6517L25.9326 11.1144C26.1215 11.3032 26.3775 11.4092 26.6444 11.4092C26.9114 11.4092 27.1676 11.3032 27.3563 11.1144L29.7053 8.7653C30.0983 8.37227 30.0983 7.7348 29.7052 7.34167ZM5.96586 27.3083C5.52859 27.7457 4.94717 27.9865 4.32879 27.9865C3.7104 27.9865 3.12889 27.7457 2.69162 27.3083C2.25424 26.8711 2.01343 26.2895 2.01343 25.6712C2.01343 25.0527 2.25424 24.4714 2.69162 24.0341L14.8993 11.8264L18.1736 15.1008L5.96586 27.3083ZM19.5973 13.6769L16.3229 10.4026L20.7718 5.95369L24.0462 9.22803L19.5973 13.6769ZM26.6443 8.97894L21.021 3.35551L21.9464 2.43015L27.5697 8.05348L26.6443 8.97894Z" fill="#D30D15"/>
                                    </svg>
                                </span>
                                анализы
                            </a>
                            <a href="/visits">
                                <span class="icon">
                                    <svg width="29" height="33" viewBox="0 0 29 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.1875 28.6094V30.5469C24.1875 31.0797 23.7516 31.5156 23.2188 31.5156H2.39062C1.85781 31.5156 1.42188 31.0797 1.42188 30.5469V2.45312C1.42188 1.92031 1.85781 1.48438 2.39062 1.48438H14.9359L24.1875 10.2516V22.6031" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M24.1875 10.6391H15.9531C15.4203 10.6391 14.9844 10.2031 14.9844 9.67031V1.96875" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M5.78125 9.71875H12.0781" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M5.78125 14.5625H20.7969" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M5.78125 17.9531H20.7969" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M5.78125 22.3125H16.4375" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M5.78125 25.2188H13.5312" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M23.2188 23.33C22.5406 21.8284 18.8594 21.7316 18.8594 24.3956C18.8594 26.4784 22.4437 29.1425 23.2188 29.1425C23.9938 29.1425 27.5781 26.4784 27.5781 24.3956C27.5781 21.4409 22.8313 21.6347 22.8313 24.7831" stroke="#D30D15" stroke-width="2"/>
                                    </svg>
                                </span>
                                история
                            </a>
                            <a href="/appointment">
                                <span class="icon">
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M24.5 8.7V23.5C24.5 23.7652 24.3946 24.0196 24.2071 24.2071C24.0196 24.3946 23.7652 24.5 23.5 24.5H2C1.73478 24.5 1.48043 24.3946 1.29289 24.2071C1.10536 24.0196 1 23.7652 1 23.5V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H17.57" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M6.48965 16.1358L6.16965 18.9308C6.1626 18.9931 6.16961 19.0562 6.19016 19.1155C6.21071 19.1748 6.24428 19.2287 6.28841 19.2733C6.33253 19.3179 6.38608 19.3521 6.44513 19.3733C6.50419 19.3945 6.56724 19.4021 6.62965 19.3958L9.40965 19.0758C9.50384 19.0641 9.59164 19.022 9.65965 18.9558L24.1996 4.36077C24.2769 4.28368 24.3382 4.1921 24.38 4.09129C24.4218 3.99048 24.4434 3.88241 24.4434 3.77327C24.4434 3.66413 24.4218 3.55606 24.38 3.45525C24.3382 3.35444 24.2769 3.26287 24.1996 3.18577L22.3246 1.30077C22.1681 1.14445 21.9559 1.05664 21.7347 1.05664C21.5134 1.05664 21.3012 1.14445 21.1447 1.30077L6.60465 15.8908C6.54001 15.9573 6.49953 16.0435 6.48965 16.1358Z" stroke="#D30D15" stroke-width="2"/>
                                        <path d="M19.1855 3.26953L22.2005 6.36953" stroke="#D30D15" stroke-width="2"/>
                                    </svg>
                                </span>
                                запись на приём
                            </a>
                            <a href="/promotions">
                                <span class="icon">
                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.35804 16.0793C0.880654 15.0805 0.880654 13.9194 1.35804 12.9206C1.56445 12.4887 1.91642 12.0758 2.62037 11.2498C2.90054 10.921 3.04063 10.7566 3.1583 10.581C3.42804 10.1786 3.61525 9.72664 3.70908 9.25134C3.75002 9.04399 3.76719 8.8287 3.80156 8.39811C3.88789 7.31625 3.93106 6.77531 4.09046 6.32401C4.45914 5.28019 5.28019 4.45916 6.32401 4.09046C6.7753 3.93106 7.31625 3.88788 8.39812 3.80155C8.82871 3.7672 9.044 3.75002 9.25135 3.70907C9.72665 3.61524 10.1786 3.42804 10.581 3.1583C10.7566 3.04063 10.921 2.90054 11.2498 2.62037C12.0758 1.91641 12.4888 1.56444 12.9206 1.35805C13.9194 0.880651 15.0806 0.880651 16.0794 1.35805C16.5112 1.56444 16.9242 1.91641 17.7503 2.62037C18.079 2.90054 18.2434 3.04063 18.419 3.1583C18.8215 3.42804 19.2734 3.61524 19.7486 3.70907C19.9561 3.75002 20.1713 3.7672 20.602 3.80155C21.6838 3.88788 22.2247 3.93106 22.676 4.09046C23.7198 4.45916 24.5409 5.28019 24.9096 6.32401M4.09046 22.676C4.45914 23.7198 5.28019 24.5409 6.32401 24.9096C6.7753 25.069 7.31625 25.1121 8.39812 25.1985C8.82871 25.2328 9.044 25.2501 9.25135 25.2909C9.72665 25.3848 10.1786 25.5719 10.581 25.8418C10.7566 25.9594 10.921 26.0994 11.2498 26.3797C12.0758 27.0836 12.4888 27.4356 12.9206 27.642C13.9194 28.1193 15.0806 28.1193 16.0794 27.642C16.5112 27.4356 16.9242 27.0836 17.7503 26.3797C18.079 26.0994 18.2434 25.9594 18.419 25.8418C18.8215 25.5719 19.2734 25.3848 19.7486 25.2909C19.9561 25.2501 20.1713 25.2328 20.602 25.1985C21.6838 25.1121 22.2247 25.069 22.676 24.9096C23.7198 24.5409 24.5409 23.7198 24.9096 22.676C25.069 22.2247 25.1121 21.6838 25.1985 20.6019C25.2328 20.1714 25.2499 19.956 25.291 19.7487C25.3847 19.2734 25.5719 18.8214 25.8418 18.419C25.9594 18.2434 26.0995 18.079 26.3797 17.7503C27.0836 16.9242 27.4356 16.5112 27.6421 16.0793C28.1193 15.0805 28.1193 13.9194 27.6421 12.9206" stroke="#D30D15" stroke-width="2" stroke-linecap="round"/>
                                        <path d="M10.1084 18.8952L18.8981 10.1055" stroke="#D30D15" stroke-width="2" stroke-linecap="round"/>
                                        <path d="M19.6281 18.1622C19.6281 18.9713 18.9723 19.6272 18.1632 19.6272C17.3541 19.6272 16.6982 18.9713 16.6982 18.1622C16.6982 17.3531 17.3541 16.6973 18.1632 16.6973C18.9723 16.6973 19.6281 17.3531 19.6281 18.1622Z" fill="#D30D15"/>
                                        <path d="M12.302 10.838C12.302 11.6471 11.6461 12.3029 10.837 12.3029C10.028 12.3029 9.37207 11.6471 9.37207 10.838C9.37207 10.0289 10.028 9.37305 10.837 9.37305C11.6461 9.37305 12.302 10.0289 12.302 10.838Z" fill="#D30D15"/>
                                    </svg>
                                </span>
                                акции
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleOnecChooseSection1 = (e) => {
        //Проверяем индекс на вхождение в ожидаемый диапазон
        if(this.state.selected_onec_index < -1)
        {
            this.setState({ error_messages: [...this.state.error_messages, "Выберите один из вариантов"] });
            return;
        }

        //Проверяем длину списка uid`ов, чтобы не выйти за границы буфера при обращении по индексу
        if(this.state.selected_onec_index >= 0 && this.state.family_data.length <= this.state.selected_onec_index)
        {
            this.setState({ error_messages: [...this.state.error_messages, "Выбранный индекс выходит за границы списка"] });
            return;
        }

        //Если выбран вариант "Меня нет в списке", применяем его сразу
        if(this.state.selected_onec_index == -1)
        {
            this.handleOnecChooseSection2(e);
            return;
        }

        //Иначе Переходим на следующую секцию
        this.setState({ onec_modal_section: OnecSections.Confirmation });
    }

    handleOnecChooseSection2 = (e) => {
        let errors = [];
        //Проверяем индекс на вхождение в ожидаемый диапазон
        if(this.state.selected_onec_index < -1)
        {
            this.setState({ error_messages: [...errors, "Выбран некорректный индекс"] });
            return;
        }

        //Проверяем длину списка uid`ов, чтобы не выйти за границы буфера при обращении по индексу
        if(this.state.selected_onec_index >= 0 && this.state.family_data.length <= this.state.selected_onec_index)
        {
            this.setState({ error_messages: [...errors, "Выбранный индекс выходит за границы списка"] });
            return;
        }

        //Запускаем анимацию загрузки
        this.setState({ loading: true });

        //Если выбран вариант "Меня нет в списке"
        if(this.state.selected_onec_index == -1)
        {
            //Перенаправляем пользователя на страницу завершения регистрации
            window.location = "/register/?section=data";
        }
        //Если выбран какой-то элемент списка
        else
        {
            //Сбрасываем ошибки в состоянии
            this.setState({ error_messages: [] });

            //Отправляем данные на сервер
            sendOnecSetByFamilyId(this.state.cabuserinfo, this.state.family_data, this.state.selected_onec_index, (errors) => this.setState({ error_messages: errors }), (e) => this.setState(e));
        }
    }
}

export default withRouter(Logged);

import styles from './Register.module.css'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import PreLoader from './../../PreLoader';
import UnloggedHeader from './../../Headers/UnloggedHeader';
import PhoneInput from './../../Inputs/PhoneInput';
import PasswordInput from './../../Inputs/PasswordInput';
import NameInput from './../../Inputs/NameInput';
import CodeInput from './../../Inputs/CodeInput';
import DateInput from './../../Inputs/DateInput';
import EmailInput from './../../Inputs/EmailInput';
import CheckboxInput from './../../Inputs/CheckboxInput';
import Notice from './../../Notice';
import auth_image from '../../assets/img/auth-image.jpg'
import ReCAPTCHA from "react-google-recaptcha";

//Перечисление секций, между которыми переключается страница
const Sections = {
	PhoneEntring: 1,
	CodeEntring: 2,
	InfoEntring: 3
}

export default function Register()
{
    //Активная секция страницы
    const [section, setSection] = useState(Sections.PhoneEntring);

    //Номер телефона из поля ввода
    const [phone, setPhone] = useState("");
    //Ошибка для вывода в форме ввода номера телефона
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

    //Пароль из поля ввода
    const [password, setPassword] = useState("");
    //Ошибка для вывода в форме ввода пароля
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
     
    //Обращение из поля ввода
    const [alias, setAlias] = useState("");
    //Ошибка для вывода в форме ввода обращения
    const [aliasErrorMessage, setAliasErrorMessage] = useState("");

    //Код из поля ввода
    const [code, setCode] = useState("");
    //Ошибка для вывода в форме ввода кода
    const [codeErrorMessage, setCodeErrorMessage] = useState("");

    //Фамилия из поля ввода
    const [lname, setLname] = useState("");
    //Ошибка для вывода в форме ввода фамилии
    const [lnameErrorMessage, setLnameErrorMessage] = useState("");
    //Имя из поля ввода
    const [fname, setFname] = useState("");
    //Ошибка для вывода в форме ввода имени
    const [fnameErrorMessage, setFnameErrorMessage] = useState("");
    //Отчество из поля ввода
    const [sname, setSname] = useState("");
    //Ошибка для вывода в форме ввода отчества
    const [snameErrorMessage, setSnameErrorMessage] = useState("");

    //Дата рождения из поля ввода
    const [birthdate, setBirthdate] = useState("");
    //Ошибка для вывода в форме ввода даты рождения
    const [birthdateErrorMessage, setBirthdateErrorMessage] = useState("");
    //Электронная почта из поля ввода
    const [email, setEmail] = useState("");
    //Ошибка для вывода в форме ввода электронной почты
    const [emailErrorMessage, setEmailErrorMessage] = useState("");

    //Список ошибок для вывода в модальном окне
    const [errorMessages, setErrorMessages] = useState([]);
    //Флаг загрузки данных (необходимости показа прелоадера)
    const [loading, setLoading] = useState(false);
    //Токен капчи
    const [capchaToken, setCapchaToken] = useState(null);

    //Информация о пользователе из личного кабинета
    const [cabuserinfo, setCabuserinfo] = useState({});
    //Информация о пользователе из 1C
    const [onec, setOnec] = useState({});

    //Согласие на обработку персональных данных
    const [confirmationForPd, setConfirmationForPd] = useState(false);

    //Объекты роутера
    const location = useLocation();
    const navigate = useNavigate();

    /* eslint-disable */
    useEffect(() => {
        document.title = "Регистрация - Здоровый ребенок";

        //Если установлен параметр ?section=data, меняем секцию
        const query = new URLSearchParams(location.search);
        let section_data = false;
        if(query.get('section') === 'data')
        {
            setSection(Sections.InfoEntring);
            section_data = true;
        }

        //Запрашиваем статус
        if(localStorage.getItem('accessToken'))
        {
            //Включаем анимацию загрузки
            setLoading(true);

            fetch('/api/getcabuserinfo', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //Если пользователь уже авторизован
                    if(data.result)
                    {
                        let do_not_redirect = false;

                        //Если пользователь не завершил регистрацию
                        if((data.body.fname === undefined || data.body.fname.length === 0) || (data.body.lname === undefined || data.body.lname.length === 0) ||
                                (data.body.sname === undefined || data.body.sname.length === 0) || (data.body.email === undefined || data.body.email.length === 0) ||
                                (!data.body.onecowner || !data.body.onecowner.length))
                        {
                            //Перенаправляем на секцию завершения регистрации, если этого еще не было сделано
                            if(section !== Sections.InfoEntring && query.get('section') !== 'data')
                                window.location = "/register/?section=data";

                            //Вытягиваем данные пользователя
                            setCabuserinfo(data.body);

                            //Устанавливаем флаг отключения перенаправления (т.к. регистрация не завершена)
                            do_not_redirect = true;
                        }

                        //Если у клиента есть 1С, запрашиваем данные из него
                        if(data.body.onecowner && data.body.onecowner.length)
                        {
                            //Включаем анимацию загрузки
                            setLoading(true);

                            fetch('/api/getclientinfo', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')}, body: JSON.stringify({ "clientId": data.body.onecowner })})
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки
                                    setLoading(false);

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    if(data.result)
                                    {
                                        //Если пользователь не завершил регистрацию, перенаправляем его на соответствующую страницу
                                        if(data.body.dob === undefined || data.body.dob.length === 0)
                                        {
                                            //Перенаправляем на секцию завершения регистрации, если этого еще не было сделано
                                            if(section !== Sections.InfoEntring && !section_data)
                                                window.location = "/register/?section=data";
                                        }
                                        //Иначе перенаправляем его на главную, если не был установлен флаг
                                        else if(!do_not_redirect)
                                           window.location = "/";

                                        //Вытягиваем данные пользователя
                                        setOnec(data.body);

                                        //Заполняем форму имеющимися данными
                                        if(data.body.dob.length >= 10)
                                            setBirthdate(data.body.dob[6] + data.body.dob[7] + data.body.dob[8] + data.body.dob[9] + "-" + data.body.dob[3] + data.body.dob[4] + "-" + data.body.dob[0] + data.body.dob[1]);
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки
                                    setLoading(false);

                                    //Устанавливаем ошибку
                                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                                });
                        }

                        //Заполняем форму имеющимися данными
                        setLname(data.body.lname);
                        setFname(data.body.fname);
                        setSname(data.body.sname);
                        setEmail(data.body.email);
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }, []);
    /* eslint-enable */

    const validatePhoneNum = (phone) => {
        //Проверяем номер телефона на длину
        if(phone.length !== 10)
        {
            setPhoneErrorMessage("Неверная длина номера телефона");
            return false;
        }

        //Проверяем номер телефона на наличие нечисловых символов
        for(let symbol of phone)
        {
            if(symbol < '0' || symbol > '9')
            {
                setPhoneErrorMessage("Номер телефона содержит неопознанные символы");
                return false;
            }
        }

        return true;
    }

    const validateAlias = (alias) => {
        //Проверяем обращение на длину
        if(alias.length < 2)
        {
            setAliasErrorMessage("Имя слишком короткое");
            return false;
        }

        return true;
    }

    const validatePassword = (password) => {
        //Проверяем пароль на длину
        if(password.length < 6)
        {
            setPasswordErrorMessage("Пароль содержит менее 6 символов");
            return false;
        }

        return true;
    }

    const validateCapcha = () => {
        //Проверяем, введена ли капча
        if(capchaToken === null)
        {
            if(errorMessages.indexOf("Вы не прошли проверку на робота") === -1)
                setErrorMessages([...errorMessages, "Вы не прошли проверку на робота"]);
            return false;
        }

        return true;
    }

    const submitPhoneHandler = (e) => {
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validatePhoneNum(phone))
            data_ok = false;
        if(!validateAlias(alias))
            data_ok = false;
        if(!validatePassword(password))
            data_ok = false;
        if(section === Sections.PhoneEntring && !validateCapcha())
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
        {
            //Включаем анимацию загрузки
            setLoading(true);

            //Отправляем данные на сервер
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "userLogin": phone, "userPassword": password, "firstName": alias })
            };
            fetch('/api/registercabuser', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(data.result === false)
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf(data.body.errorAns) === -1)
                            setErrorMessages([...errorMessages, data.body.errorAns]);
                    }
                    //Иначе переходим к следующей секции
                    else
                    {
                        setErrorMessages([]);
                        setSection(Sections.CodeEntring);
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }

    const repeatCall = (e) => {
        //Включаем анимацию загрузки
        setLoading(true);

        //Отправляем данные на сервер
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "userLogin": phone })
        };
        fetch('/api/RepeatCall', request)
            .then(response => response)
            .then(data => {
                //Выключаем анимацию загрузки
                setLoading(false);
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                setLoading(false);
                //Устанавливаем ошибку
                if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                    setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
            });
    }

    const validateCode = (code) => {
        //Проверяем код на длину
        if(code.length !== 4)
        {
            setCodeErrorMessage("Код не заполнен");
            return false;
        }

        return true;
    }

    const submitCodeHandler = (e) => {
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validateCode(code))
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
        {
            //Включаем анимацию загрузки
            setLoading(true);

            //Отправляем данные на сервер
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "userLogin": phone, "userPassword": password, "conformationCode": code })
            };
            fetch('/api/ApproveCabUserNumber', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(data.result === false)
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf(data.body.errorAns) === -1)
                            setErrorMessages([...errorMessages, data.body.errorAns]);
                    }
                    else
                    {
                        if(data.body.accessToken)
                        {
                            //Сохраняем полученный от сервера токен
                            localStorage.setItem('accessToken', data.body.accessToken);
                            //Переходим к следующей секции
                            window.location = "/register/?section=data";
                        }
                        else
                        {
                            //Устанавливаем ошибку и прекращаем обработку
                            if(errorMessages.indexOf("Сервер не вернул токен авторизации") === -1)
                                setErrorMessages([...errorMessages, "Сервер не вернул токен авторизации"]);
                            return;
                        }
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);
                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }

    const sendOnecSet = (errors_callback) => {
        //Включаем анимацию загрузки
        setLoading(true);

        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({ "uid": onec.UID ? onec.UID : "", "fname": fname ? fname : "", "lname": lname ? lname : "",
                                    "sname": sname ? sname : "", "email": email ? email : "", "onecowner": cabuserinfo.onecowner ? cabuserinfo.onecowner : "",
                                    "ids": cabuserinfo.onec_uids ? cabuserinfo.onec_uids : [],
                                    "bdate": birthdate && birthdate.length >= 10 ? (birthdate[0] + birthdate[1] + birthdate[2] + birthdate[3] + birthdate[5] + birthdate[6] + birthdate[8] + birthdate[9] + "000000") : "",
                                    "sex": onec.sex ? onec.sex : "",
                                    "tel": cabuserinfo.id ? cabuserinfo.id + "" : "", "telw": onec.telw ? onec.telw : "", "lrid": onec.lrid ? onec.lrid : "",
                                    "addrlgstr": onec.addrlgstr ? onec.addrlgstr: "",
                                    "addrlg": onec.addrlg && onec.addrlg.length > 0 ?
                                                { "idx": onec.addrlg[0].idx ? onec.addrlg[0].idx : "", "cn": onec.addrlg[0].cn ? onec.addrlg[0].cn : "",
                                                  "rg": onec.addrlg[0].rg ? onec.addrlg[0].rg : "", "tn": onec.addrlg[0].tn ? onec.addrlg[0].tn : "",
                                                  "st": onec.addrlg[0].st ? onec.addrlg[0].st : "", "hs": onec.addrlg[0].hs ? onec.addrlg[0].hs : "",
                                                  "cp": onec.addrlg[0].cp ? onec.addrlg[0].cp : "", "ft": onec.addrlg[0].ft ? onec.addrlg[0].ft : ""} :
                                                { "idx": "", "cn": "", "rg": "", "tn": "", "st": "", "hs": "", "cp": "", "ft": "" },
                                    "addrfcstr": onec.addrfcstr ? onec.addrfcstr : "",
                                    "addrfc": onec.addrfc && onec.addrfc.length > 0 ?
                                                { "idx": onec.addrfc[0].idx ? onec.addrfc[0].idx : "", "cn": onec.addrfc[0].cn ? onec.addrfc[0].cn : "",
                                                  "rg": onec.addrfc[0].rg ? onec.addrfc[0].rg : "", "tn": onec.addrfc[0].tn ? onec.addrfc[0].tn : "",
                                                  "st": onec.addrfc[0].st ? onec.addrfc[0].st : "", "hs": onec.addrfc[0].hs ? onec.addrfc[0].hs : "",
                                                  "cp": onec.addrfc[0].cp ? onec.addrfc[0].cp : "", "ft": onec.addrfc[0].ft ? onec.addrfc[0].ft : ""} :
                                                { "idx": "", "cn": "", "rg": "", "tn": "", "st": "", "hs": "", "cp": "", "ft": ""},
                                    "isid": onec.isource ? onec.isource : "", "adid": onec.adid ? onec.adid : "",
                                    "respid": onec.respid ? onec.respid: "", "cmt": onec.cmt ? onec.cmt : "",
                                    "pass": onec.pass && onec.pass.length > 0 ?
                                                { "ser": onec.pass[0].ser ? onec.pass[0].ser : "", "num": onec.pass[0].num ? onec.pass[0].num : "",
                                                  "udate": onec.pass[0].udate ? onec.pass[0].udate : "", "ucode": onec.pass[0].ucode ? onec.pass[0].ucode : "",
                                                  "unit": onec.pass[0].unit ? onec.pass[0].unit : "" } :
                                                { "ser": "", "num": "", "udate": "", "ucode": "", "unit": "" }, "vrf": onec.vrf ? onec.vrf: "0"
                                })
        };
        fetch('/api/SetUserInfo', request)
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                setLoading(false);

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                        setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(data.result === false)
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(errorMessages.indexOf(data.body.errorAns) === -1)
                        setErrorMessages([...errorMessages, data.body.errorAns]);
                }
                else
                {
                    console.log(3);
                    //Сбрасываем ошибки в состоянии через callback
                    errors_callback([]);

                    //Включаем анимацию загрузки
                    setLoading(true);

                    //Переспрашиваем данные ЛК и 1С
                    fetch('/api/getcabuserinfo', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
                        .then(response => response.json())
                        .then(data => {
                            //Выключаем анимацию загрузки
                            setLoading(false);

                            //Проверяем наличие обязательных полей в ответе
                            if(!('result' in data) || !('body' in data))
                            {
                                //Устанавливаем ошибку и прекращаем обработку
                                if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                                    setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                                return;
                            }

                            //Если пользователь уже авторизован
                            if(data.result)
                            {
                                let do_not_redirect = false;

                                //Если пользователь не завершил регистрацию
                                if((data.body.fname === undefined || data.body.fname.length === 0) || (data.body.lname === undefined || data.body.lname.length === 0) ||
                                        (data.body.sname === undefined || data.body.sname.length === 0) || (data.body.email === undefined || data.body.email.length === 0) ||
                                        (!data.body.onecowner || !data.body.onecowner.length))
                                {
                                    //Перенаправляем на секцию завершения регистрации, если этого еще не было сделано
                                    if(section !== Sections.InfoEntring)
                                        window.location = "/register/?section=data";

                                    //Вытягиваем данные пользователя
                                    setCabuserinfo(data.body);

                                    //Устанавливаем флаг отключения перенаправления (т.к. регистрация не завершена)
                                    do_not_redirect = true;
                                }

                                //Включаем анимацию загрузки
                                setLoading(true);

                                fetch('/api/getclientinfo', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')}, body: JSON.stringify({ "clientId": data.body.onecowner })})
                                    .then(response => response.json())
                                    .then(data => {
                                        //Выключаем анимацию загрузки
                                        setLoading(false);

                                        //Проверяем наличие обязательных полей в ответе
                                        if(!('result' in data) || !('body' in data))
                                        {
                                            //Устанавливаем ошибку и прекращаем обработку
                                            if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                                                setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                                            return;
                                        }

                                        if(data.result)
                                        {
                                            //Если пользователь не завершил регистрацию, перенаправляем его на соответствующую страницу
                                            if(data.body.dob === undefined || data.body.dob.length === 0)
                                            {
                                                //Перенаправляем на секцию завершения регистрации, если этого еще не было сделано
                                                if(section !== Sections.InfoEntring)
                                                    window.location = "/register/?section=data";
                                            }
                                            //Иначе перенаправляем его на главную, если не был установлен флаг
                                            else if(!do_not_redirect)
                                               window.location = "/";

                                            //Вытягиваем данные пользователя
                                            setOnec(data.body);

                                            //Заполняем форму имеющимися данными
                                            if(data.body.dob.length >= 10)
                                                setBirthdate(data.body.dob[6] + data.body.dob[7] + data.body.dob[8] + data.body.dob[9] + "-" + data.body.dob[3] + data.body.dob[4] + "-" + data.body.dob[0] + data.body.dob[1]);
                                        }
                                        else
                                            //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                            if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                            {
                                                localStorage.removeItem('accessToken');
                                                window.location = "/login";
                                            }
                                    })
                                    .catch((error) => {
                                        //Выключаем анимацию загрузки
                                        setLoading(false);
                                        //Устанавливаем ошибку
                                        if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                                            setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                                    });

                                //Заполняем форму имеющимися данными
                                setLname(data.body.lname);
                                setFname(data.body.fname);
                                setSname(data.body.sname);
                                setEmail(data.body.email);
                            }
                        })
                        .catch((error) => {
                            //Выключаем анимацию загрузки
                            setLoading(false);
                            //Устанавливаем ошибку
                            if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                                setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                        });
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                setLoading(false);
                //Устанавливаем ошибку
                if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                    setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
            });
    }

    const validateLname = (lname) => {
        //Проверяем фамилию на длину
        if(lname.length < 2)
        {
            setLnameErrorMessage("Имя слишком короткое");
            return false;
        }

        return true;
    }

    const validateFname = (fname) => {
        //Проверяем имя на длину
        if(fname.length < 2)
        {
            setFnameErrorMessage("Имя слишком короткое");
            return false;
        }

        return true;
    }

    const validateSname = (sname) => {
        //Проверяем отчество на длину
        if(sname.length < 2)
        {
            setSnameErrorMessage("Имя слишком короткое");
            return false;
        }

        return true;
    }

    const validateBirthdate = (birthdate) => {
        let year = 0 + birthdate.slice(0, birthdate.indexOf("-"));
        if(year < 1923 || year > (new Date().getFullYear() - 14))
        {
            setBirthdateErrorMessage("Год рождения некорректен");
            return false;
        }

        return true;
    }

    const validateEmail = (email) => {
        if(! /\S+@\S+\.\S+/.test(email))
        {
            setEmailErrorMessage("Email некорректен");
            return false;
        }

        return true;
    }

    const validateConformationForPd = (confirmationForPd) => {
        if(!confirmationForPd)
        {
            if(errorMessages.indexOf("Необходимо согласие на обработку персональных данных") === -1)
                setErrorMessages([...errorMessages, "Необходимо согласие на обработку персональных данных"]);
            return false;
        }

        return true;
    }

    const submitInfoHandler = (e) => {
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validateLname(lname))
            data_ok = false;
        if(!validateFname(fname))
            data_ok = false;
        if(!validateSname(sname))
            data_ok = false;
        if(!validateBirthdate(birthdate))
            data_ok = false;
        if(!validateEmail(email))
            data_ok = false;
        if(!validateConformationForPd(confirmationForPd))
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
            sendOnecSet((errors) => setErrorMessages(errors));
    }

    if(section === Sections.PhoneEntring)
        return (
            <>
                {loading && <PreLoader />}
                <UnloggedHeader link="/login" />
                <main>
                    <div className={styles.fullScreen}>
                        <div className={[styles.container, styles.flexRow].join(' ')}>
                            <div className={styles.fullScreenContent}>
                                <form className={styles.registerForm}>
                                    <p className={styles.formTitle}>
                                        Регистрация
                                    </p>
                                    <p className={styles.formDescription}>
                                        Пожалуйста, укажите Ваш мобильный телефон для проверки его наличия в нашей системе
                                    </p>
                                    <PhoneInput phone={phone} setPhone={setPhone} error_message={phoneErrorMessage} reset_error_callback={ () => setPhoneErrorMessage("") } auto_focus={true}/>
                                    <NameInput name={alias} setName={setAlias} label="Как к вам обращаться" id="name" placeholder="Имя" auto_focus={false}
                                               error_message={aliasErrorMessage} reset_error_callback={ () => setAliasErrorMessage("") } />
                                    <PasswordInput password={password} setPassword={setPassword} label="Пароль" id="password-input" auto_focus={false}
                                               error_message={passwordErrorMessage} reset_error_callback={ () => setPasswordErrorMessage("") }/>
                                    <div className={styles.capcha}>
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_CAPCHA_SITE_KEY}
                                            onChange={ (token) => setCapchaToken(token) }/>
                                    </div>
                                    <input type="submit" className={styles.buttonPrimary} value="Далее" onClick={submitPhoneHandler}></input>
                                </form>
                                <p>
                                    <a href="/login">Вернуться ко входу в личный кабинет</a>
                                </p>
                            </div>
                            <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                        </div>
                    </div>
                </main>
                <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
            </>
        );
    else if(section === Sections.CodeEntring)
        return (
            <>
                {loading && <PreLoader />}
                <UnloggedHeader link="/login" />
                <main>
                    <div className={styles.fullScreen}>
                        <div className={[styles.container, styles.flexRow].join(' ')}>
                            <div className={styles.fullScreenContent}>
                                <form className={styles.registerForm}>
                                    <p className={styles.formTitle}>
                                        Регистрация
                                    </p>
                                    <p className={styles.formDescription}>
                                        На Ваш номер телефона, указанный ранее, сейчас поступит звонок, и будет продиктован код
                                    </p>
                                    <CodeInput code={code} setCode={setCode} repeat_callback={repeatCall}
                                               error_message={codeErrorMessage} reset_error_callback={ () => setCodeErrorMessage("") }/>
                                    <input type="submit" className={styles.buttonPrimary} value="Далее" onClick={submitCodeHandler}></input>
                                </form>
                                <p>
                                    <a href="/login">Вернуться ко входу в личный кабинет</a>
                                </p>
                            </div>
                            <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                        </div>
                    </div>
                </main>
                <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
            </>
        );
    else
        return (
            <>
                {loading && <PreLoader />}
                <UnloggedHeader link="/login" />
                <main>
                    <div className={styles.fullScreen}>
                        <div className={[styles.container, styles.flexRow].join(' ')}>
                            <div className={styles.fullScreenContent}>
                                <form className={[styles.registerForm, styles.step3].join(' ')}>
                                    <p className={styles.formTitle}>
                                        Регистрация
                                    </p>
                                    <NameInput name={lname} setName={setLname} label="Фамилия" id="lastname" placeholder="Введите вашу фамилию" auto_focus={false}
                                               error_message={lnameErrorMessage} reset_error_callback={ () => setLnameErrorMessage("") } />
                                    <NameInput name={fname} setName={setFname} label="Имя" id="name" placeholder="Введите ваше имя" auto_focus={false}
                                               error_message={fnameErrorMessage} reset_error_callback={ () => setFnameErrorMessage("") } />
                                    <NameInput name={sname} setName={setSname} label="Отчество" id="fathername" placeholder="Введите ваше отчество" auto_focus={false}
                                               error_message={snameErrorMessage} reset_error_callback={ () => setSnameErrorMessage("") } />
                                    <DateInput date={birthdate} setDate={setBirthdate} label="Дата рождения" id="birthday" auto_focus={false}
                                               error_message={birthdateErrorMessage} reset_error_callback={ () => setBirthdateErrorMessage("") }/>
                                    <EmailInput email={email} setEmail={setEmail} label="Электронная почта" id="email" auto_focus={false}
                                               error_message={emailErrorMessage} reset_error_callback={ () => setEmailErrorMessage("") }/>
                                    <CheckboxInput flag={confirmationForPd} setFlag={setConfirmationForPd} id="agreement" bottom_text="*Все поля обязательны для заполнения"
                                            label="Я даю согласие на обработку моих персональных данных и принимаю пользовательское соглашение" />
                                    <div className={styles.buttonGroup}>
                                        <a href="#" className={styles.buttonLightgray} onClick={(e) => {localStorage.removeItem('accessToken'); navigate("/login");}}>Назад</a>
                                        <input type="submit" className={styles.buttonPrimary} value="Далее" onClick={ submitInfoHandler }></input>
                                    </div>
                                </form>
                                <p>
                                    <a href="#" onClick={(e) => {localStorage.removeItem('accessToken'); navigate("/login");}}>Вернуться ко входу в личный кабинет</a>
                                </p>
                            </div>
                            <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                        </div>
                    </div>
                </main>
                <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
            </>
        );
}

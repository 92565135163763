import styles from './DateInput.module.css'

export default function DateInput({date = "", setDate = (value) => {}, label = "Дата", id = "birthday", placeholder = "Введите", auto_focus = false, error_message = "", reset_error_callback = () => {}})
{
    return (
        <div className={styles.formGroup}>
            <input id={id} name={id} className={error_message.length ? [styles.formInput, styles.error].join(' ') : styles.formInput} type="date" placeholder={placeholder} value={ date }
                onChange={(e) => { setDate(e.target.value); reset_error_callback(); }}></input>
            <label htmlFor={id} className={styles.formLabel}>{label}</label>
            <span className={styles.formClear} onClick={(e) => { setDate(""); reset_error_callback(); }}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" strokeWidth="0.909137" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            {error_message.length !== 0 &&
            <div className={styles.formError}>{error_message}</div>
            }
        </div>
    );
};

import React from 'react';
import '../assets/css/main.css';
import '../assets/css/nice-select2.css';
import '../assets/css/swiper-bundle.min.css';
import {Notice, initForms, initLayoutScripts} from '../assets/js/notice.js'

let was_inited = false;

export default class Contacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loading: false, business_units: {}, error_messages: props.error_messages, update_error_messages_callback: props.update_error_messages_callback, iframe_src: "" };

        //Если пользователь не авторизован, перенаправляем его на страницу вход
        if(!localStorage.getItem('accessToken'))
            window.location = "/login";
    }

    componentDidUpdate(prevProps) {
        //Если есть ошибки, выводим всплывающее окно с ними
        if(this.state.error_messages.length > 0)
            Notice.show(this.state.error_messages.join('<br/>'));

        if(prevProps.error_messages !== this.props.error_messages)
            this.setState({error_messages: this.props.error_messages});
        if(prevProps.update_error_messages_callback !== this.props.update_error_messages_callback)
            this.setState({update_error_messages_callback: this.props.update_error_messages_callback});

        if(this.state.need_to_init)
        {
            //Инициализируем скрипты от верстальщиков
            if(was_inited)
                initLayoutScripts();
            was_inited = true;
            initLayoutScripts();
            Notice.setHideCallback(() => { this.setState({error_messages: []}); this.state.update_error_messages_callback([]); });
            this.setState({need_to_init: false});
        }
    }

    componentDidMount(prevProps) {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        //Запрашиваем конфиг
        fetch(`/config.json?timestamp=${new Date().getTime()}`, {method: 'GET'})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Парсим данные
                this.setState({iframe_src: data.iframeSrc});

                //Запрашиваем бизнес-подразделения
                this.get_bus();
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);

                //Запрашиваем бизнес-подразделения
                this.get_bus();
            });
    }

    get_bus = () => {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        //Запрашиваем подразделения
        fetch('/api/GetBusinessUnitsList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(!data.result)
                {
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
                    else
                        if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                }
                else
                {
                    //Парсим данные
                    let business_units = {};
                    for(let bu of data.body)
                    {
                        business_units[bu.bu_id] = {};
                        business_units[bu.bu_id].bu_name = bu.bu_altname ? bu.bu_altname : bu.bu_name;
                        business_units[bu.bu_id].bu_addr = bu.bu_addr;
                    }
                    this.setState({ business_units: business_units });

                    if(Object.keys(business_units).length > 0)
                    {
                        //Включаем анимацию загрузки
                        this.setState({ loading: true });

                        //Сбрасываем ошибки
                        this.state.update_error_messages_callback([]);

                        //Запрашиваем данные по конкретным бизнес-подразделениям
                        //Сколько профилей подразделений обработано
                        let bu_profiles_counter = 0;
                        for(let bu_id of Object.keys(business_units))
                        {
                            //Запрашиваем профили подразделений
                            const request = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "buId": bu_id + "" })
                            };
                            fetch('/api/GetBuInfo', request)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        business_units[bu_id] = {...business_units[bu_id], ...data.body}
                                    }

                                    //Сохраняем список, если все запросы обработаны
                                    if(bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({ business_units: business_units, need_to_init: true });
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});
                                    //Устанавливаем ошибку
                                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                });
                        }
                    }
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    uniqueBy = (a, cond) => {
      return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i);
    }

    render() {
        return (
            <>
                {this.state.loading &&
                <div class="pre-loader">
                    <div class="loader-spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                }

                <div class="side-content">
                    <h1>
                        Контакты
                    </h1>

                    <p>
                        «Здоровый ребенок» - это Группа Компаний, не имеющая аналогов среди клиник и многопрофильных центров России, которая включает в себя комплекс жизненно-важных профессиональных направлений в сфере лечения и развития семьи.
                    </p>

                    <div class="contacts-tab-control">
                        {this.uniqueBy(Object.values(this.state.business_units), (o1, o2) => o1.TOWN === o2.TOWN).map((data, index) =>
                        <span class={index == 0 ? "contacts-tab-control--item selected" : "contacts-tab-control--item"} key={index}>
                            {data.TOWN}
                        </span>)}
                    </div>


                    {this.uniqueBy(Object.values(this.state.business_units), (o1, o2) => o1.TOWN === o2.TOWN).map((data, index) =>
                    <div key={index}>
                        <p class="mobile-hidden">
                            <b>{data.TOWN}</b>
                        </p>

                        <div class={index == 0 ? "contacts-tab--item show" : "contacts-tab--item"}>
                            {Object.values(this.state.business_units).filter((filtering_data) => filtering_data.TOWN == data.TOWN).map((bu_data, bu_index) =>
                            <div>
                                <ul>
                                    <li><b>{bu_data.bu_altname ? bu_data.bu_altname : bu_data.bu_name} по адресу {bu_data.bu_addr}:</b></li>
                                </ul>
                                <p>
                                    Телефон: <b><a href={"tel:" + bu_data.TEL}>{bu_data.TEL && bu_data.TEL.length >= 12 ? (bu_data.TEL[0] + bu_data.TEL[1] + " (" + bu_data.TEL[2] + bu_data.TEL[3] + bu_data.TEL[4] + ") " +
                                                                             bu_data.TEL[5] + bu_data.TEL[6] + bu_data.TEL[7] + " " + bu_data.TEL[8] + bu_data.TEL[9] + " " +
                                                                             bu_data.TEL[10] + bu_data.TEL[11]) : bu_data.TEL}</a></b><br/>
                                    {bu_data.BUWT !== false && <span>График работы центра: {bu_data.BUWT}<br/></span>}
                                    {bu_data.LABWT !== false && <span>График работы лаборатории: {bu_data.LABWT}<br/></span>}
                                    {bu_data.BUCMT !== false && <span>{bu_data.BUCMT}<br/></span>}
                                </p>
                            </div>)}
                        </div>
                    </div>)}

                {this.state.iframe_src.length > 0 &&
                    <div class="contacts-map">
                        <div style={{"position": "relative", "overflow": "hidden"}}><iframe src={this.state.iframe_src} width="100%" height="400" frameborder="0"></iframe></div>
                    </div>
                }
                </div>
            </>
        );
    }
}

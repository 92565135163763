import React from 'react';
import ReactDOM from 'react-dom/client';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Login from './Pages/Login/Login';
import Restore from './Pages/Restore/Restore';
import Register from './Pages/Register/Register';
import Page404 from './Pages/Page404/Page404';
import Logged from './Logged';
import Enroll from './Enroll/Enroll';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CacheBuster currentVersion={packageInfo.version} isEnabled={true} isVerboseMode={false}>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />}/>
                <Route path="/restore" element={<Restore />}/>
                <Route path="/register" element={<Register />}/>
                <Route path="/tests" element={<Logged />}/>
                <Route path="/visits" element={<Logged />}/>
                <Route path="/profile" element={<Logged />}/>
                <Route path="/promotions" element={<Logged />}/>
                <Route path="/6" element={<Logged />}/>
                <Route path="/contacts" element={<Logged />}/>
                <Route path="/service" element={<Logged />}/>
                <Route path="/doctors" element={<Logged />}/>
                <Route path="/appointment" element={<Logged />}/>
                <Route path="/enroll/*" element={<Enroll />}/>
                <Route path="/" element={<Logged />}/>
                <Route path="*" element={<Page404 />}/>
            </Routes>
        </BrowserRouter>
    </CacheBuster>
);

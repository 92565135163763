import React from 'react';
import '../assets/css/main.css';
import '../assets/css/nice-select2.css';
import '../assets/css/swiper-bundle.min.css';
import {Notice, initForms, initLayoutScripts} from '../assets/js/notice.js'
import person_detail from '../assets/img/person-detail.png'

let data_requirement_was_showed = false;

export default class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = { avatar_url: "", cabuserinfo: props.cabuserinfo, onec: props.onec, family_data: props.family_data, contracts: [], agreements: [], vhi: [], loading: false,
                        error_messages: props.error_messages, update_error_messages_callback: props.update_error_messages_callback, can_edit: props.can_edit, open_addr_fc_modal: false,
                        addr_fc_temp: {}, send_onec_set_callback: props.send_onec_set_callback, open_addr_lg_modal: false, addr_lg_temp: {}, open_passport_modal: false, passport_temp: {},
                        open_fam_member_modal: false, new_family_member_lname: "", new_family_member_fname: "", new_family_member_sname: "", new_family_member_birthdate: "", new_family_member_sex: "",
                        get_user_info_callback: props.get_user_info_callback, confirmation_for_pd: false, less_than_fifteen: true, new_family_member_pass_ser: "", new_family_member_pass_num: "",
                        new_family_member_pass_date: "", new_family_member_pass_code: "", new_family_member_pass_unit: "", new_family_member_addr_cn: "", new_family_member_addr_rg: "",
                        new_family_member_addr_idx: "", new_family_member_addr_tn: "", new_family_member_addr_st: "", new_family_member_addr_hs: "", new_family_member_addr_cp: "",
                        new_family_member_addr_ft: "", new_lname: "", new_fname: "", new_sname: ""};
    }

    componentDidUpdate(prevProps) {
        //Если есть ошибки, выводим всплывающее окно с ними
        if(this.state.error_messages.length > 0)
            Notice.show(this.state.error_messages.join('<br/>'));

        if(prevProps.cabuserinfo !== this.props.cabuserinfo)
            this.setState({cabuserinfo: this.props.cabuserinfo});
        if(prevProps.onec !== this.props.onec)
            this.setState({onec: this.props.onec});
        if(prevProps.family_data !== this.props.family_data)
            this.setState({family_data: this.props.family_data});
        if(prevProps.error_messages !== this.props.error_messages)
            this.setState({error_messages: this.props.error_messages});
        if(prevProps.update_error_messages_callback !== this.props.update_error_messages_callback)
            this.setState({update_error_messages_callback: this.props.update_error_messages_callback});
        if(prevProps.can_edit !== this.props.can_edit)
            this.setState({can_edit: this.props.can_edit});
        if(prevProps.send_onec_set_callback !== this.props.send_onec_set_callback)
            this.setState({send_onec_set_callback: this.props.send_onec_set_callback});
        if(prevProps.get_user_info_callback !== this.props.get_user_info_callback)
            this.setState({get_user_info_callback: this.props.get_user_info_callback});

        if(!data_requirement_was_showed && this.state.can_edit == true && this.props.onec && this.props.onec.addrfcstr != undefined && this.props.onec.addrlgstr != undefined &&
            (this.props.onec.addrfcstr == "" || this.props.onec.addrlgstr == "" || this.props.onec.pass == undefined || this.props.onec.pass.length === 0))
        {
            if(this.state.error_messages.indexOf("Пожалуйста, введите данные о себе - это ускорит оформление при посещении клиники") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Пожалуйста, введите данные о себе - это ускорит оформление при посещении клиники"]);

            data_requirement_was_showed = true;
        }
    }

    componentDidMount() {
        //Инициализируем скрипты от верстальщиков
        initLayoutScripts();
        Notice.setHideCallback(() => { this.setState({error_messages: []}); this.state.update_error_messages_callback([]); });

        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Счетчик обработанных запросов
        const requests_to_fetch = 3;
        let requests_processed = 0;

        //Запрашиваем договоры
        fetch('/api/GetAgreementList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ contracts: data.body.filter((row, index) => index !== 0 && index % 2 !== 0).filter((data) => data.length > 0) });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем согласия
        fetch('/api/GetInsPolicyApprList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ agreements: data.body.filter((row, index) => index !== 0 && index % 2 !== 0).filter((data) => data.length > 0) });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем ДМС
        fetch('/api/GetInsPolicyList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ vhi: data.body.filter((row, index) => index !== 0 && index % 2 !== 0).filter((data) => data.length > 0) });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    render() {
        return (
            <>
                {this.state.loading &&
                <div class="pre-loader">
                    <div class="loader-spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                }

                <div class="profile">
                    <div class="profile-alert">
                        <div class="profile-alert--content">
                            <div class="profile-alert--close">
                                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.33105 10.0914L10.6045 1.09137M1.33105 1L10.6045 9.99999" stroke="white" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники
                        </div>
                    </div>
                    <div class="flex-row align-center">
                        {this.state.avatar_url.length > 0 && <span class="profile--photo" style={{backgroundImage: `url(${this.state.avatar_url})`}}></span>}
                        {this.state.avatar_url.length === 0 && <span class="profile--photo-null">{((this.state.cabuserinfo && this.state.cabuserinfo.lname && this.state.cabuserinfo.lname.length > 0) ? this.state.cabuserinfo.lname.charAt(0) : 'X') +
                                                                                                  ((this.state.cabuserinfo && this.state.cabuserinfo.fname && this.state.cabuserinfo.fname.length > 0) ? this.state.cabuserinfo.fname.charAt(0) : 'Y')}</span>}
                        <p class={this.state.can_edit ? "profile--name editable" : "profile--name"} onClick={(e) => {if(!this.state.can_edit) return;  this.setState({new_lname: this.state.cabuserinfo.lname, new_fname: this.state.cabuserinfo.fname, new_sname: this.state.cabuserinfo.sname}); const form = document.getElementById("change_names"); if(form) form.classList.add('open')}}>
                            <div>{((this.state.cabuserinfo && this.state.cabuserinfo.lname) ? this.state.cabuserinfo.lname : "") + " "}</div>
                            {this.state.can_edit === true && <br/>}
                            <div>
                            {((this.state.cabuserinfo && this.state.cabuserinfo.fname) ? this.state.cabuserinfo.fname : "") + " " +
                            ((this.state.cabuserinfo && this.state.cabuserinfo.sname) ? this.state.cabuserinfo.sname : "")}
                            </div>
                        </p>
                    </div>
                    <div class="flex-row profile--info">
                        <div class="col">
                            <p class="profile--title">
                                МОИ ДАННЫЕ
                            </p>
                            <div class="profile--field">
                                <div class="profile--field__title">
                                    <span class="text">
                                        Электронная почта
                                    </span>
                                    <span class="icon">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="profile--field__value">
                                    <input type="text" class="input-transparent" value={this.state.cabuserinfo.email ? this.state.cabuserinfo.email : ""} readonly onClick={(e) => {if(this.state.error_messages.indexOf("Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники") === -1) this.state.update_error_messages_callback([...this.state.error_messages, "Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники"]);}}></input>
                                </div>
                            </div>
                            <div class="profile--field">
                                <div class="profile--field__title">
                                    <span class="text">
                                        Номер телефона
                                    </span>
                                    <span class="icon">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                </div>
                                <div class="profile--field__value">
                                    <input type="text" class="input-transparent" value={this.state.onec.tel ? this.state.onec.tel : ""} readonly onClick={(e) => {if(this.state.error_messages.indexOf("Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники") === -1) this.state.update_error_messages_callback([...this.state.error_messages, "Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники"]);}}></input>
                                </div>
                            </div>
                            <div class="profile--field">
                                <div class="profile--field__title">
                                    <span class="text">
                                        Адрес проживания
                                    </span>
                                    {this.state.can_edit && <span class="icon">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.3759 11.5C14.3759 13.0879 13.0888 14.375 11.5009 14.375C9.91318 14.375 8.62598 13.0879 8.62598 11.5C8.62598 9.91214 9.91318 8.625 11.5009 8.625C13.0888 8.625 14.3759 9.91214 14.3759 11.5Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11.5006 4.79102C7.20949 4.79102 3.57714 7.61128 2.35596 11.4993C3.57712 15.3874 7.20949 18.2077 11.5006 18.2077C15.7916 18.2077 19.424 15.3874 20.6452 11.4993C19.424 7.6113 15.7916 4.79102 11.5006 4.79102Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>}
                                    {!this.state.can_edit && <span class="icon">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>}
                                </div>
                                <div class="profile--field__value">
                                    <input type="text" class="input-transparent" data-form-id="show_addr_fc" value={this.state.onec.addrfcstr ? this.state.onec.addrfcstr : ""} readonly onClick={this.handleAddrFc}></input>
                                </div>
                            </div>
                            <div class="profile--field">
                                <div class="profile--field__title">
                                    <span class="text">
                                        Адрес регистрации
                                    </span>
                                    {this.state.can_edit && <span class="icon">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.3759 11.5C14.3759 13.0879 13.0888 14.375 11.5009 14.375C9.91318 14.375 8.62598 13.0879 8.62598 11.5C8.62598 9.91214 9.91318 8.625 11.5009 8.625C13.0888 8.625 14.3759 9.91214 14.3759 11.5Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11.5006 4.79102C7.20949 4.79102 3.57714 7.61128 2.35596 11.4993C3.57712 15.3874 7.20949 18.2077 11.5006 18.2077C15.7916 18.2077 19.424 15.3874 20.6452 11.4993C19.424 7.6113 15.7916 4.79102 11.5006 4.79102Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>}
                                    {!this.state.can_edit && <span class="icon">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>}
                                </div>
                                <div class="profile--field__value">
                                    <input type="text" class="input-transparent" data-form-id="show_addr_lg" value={this.state.onec.addrlgstr ? this.state.onec.addrlgstr : ""} readonly onClick={this.handleAddrLg}></input>
                                </div>
                            </div>
                            <p class="profile--title">
                                МОИ ДОКУМЕНТЫ
                            </p>
                            <div class="profile--field">
                                <div class="profile--field__title">
                                    <span class="text">
                                        Паспорт
                                    </span>
                                    <span class="icon">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.3759 11.5C14.3759 13.0879 13.0888 14.375 11.5009 14.375C9.91318 14.375 8.62598 13.0879 8.62598 11.5C8.62598 9.91214 9.91318 8.625 11.5009 8.625C13.0888 8.625 14.3759 9.91214 14.3759 11.5Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M11.5006 4.79102C7.20949 4.79102 3.57714 7.61128 2.35596 11.4993C3.57712 15.3874 7.20949 18.2077 11.5006 18.2077C15.7916 18.2077 19.424 15.3874 20.6452 11.4993C19.424 7.6113 15.7916 4.79102 11.5006 4.79102Z" stroke="#7E7E7E" stroke-width="1.91667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    {!this.state.can_edit && <span class="icon">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>}
                                </div>
                                <div class="profile--field__value value-row">
                                    <div class="value-col">
                                        <span class="name" data-form-id="show_passport" onClick={this.handlePassport}>Серия</span>
                                        <span class="value">
                                            <input type="text" class="input-transparent" value={(this.state.onec !== undefined && this.state.onec.pass !== undefined && this.state.onec.pass.length > 0 && this.state.onec.pass[0]) ? this.state.onec.pass[0].ser : ""} readonly data-form-id="show_passport" onClick={this.handlePassport}></input>
                                        </span>
                                    </div>
                                    <div class="value-col">
                                        <span class="name" data-form-id="show_passport" onClick={this.handlePassport}>Номер</span>
                                        <span class="value">
                                            <input type="text" class="input-transparent" value={(this.state.onec !== undefined && this.state.onec.pass !== undefined && this.state.onec.pass.length > 0 && this.state.onec.pass[0]) ? this.state.onec.pass[0].num : ""} readonly data-form-id="show_passport" onClick={this.handlePassport}></input>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {this.state.contracts.map((external_data) => external_data.map((data, index) =>
                                                                        <a href="#" class="file-download" key={index} data-caid={data.caid} onClick={this.openDocument}>
                                                                            {data.catname}
                                                                            <span class="icon">
                                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.714 6.14807L14.259 0.363037C14.07 0.164037 13.803 0 13.529 0H1.979C0.874004 0 0 1 0 2.104V9.104C0 9.656 0.437014 10 0.989014 10H0.993988C1.54699 10 2 9.656 2 9.104V3.104C2 2.552 2.427 2 2.979 2H12V6.104C12 7.209 12.874 8 13.979 8H18V9.104C18 9.656 18.437 10 18.989 10H18.994C19.547 10 20 9.656 20 9.104V6.83704C20 6.58004 19.891 6.33407 19.714 6.14807ZM3.979 15.104C3.979 14.552 3.531 14.104 2.979 14.104H1.979V16.104H2.979C3.531 16.104 3.979 15.656 3.979 15.104ZM5.97501 14.838C6.06501 16.568 4.689 18 2.979 18H2V19.104C2 19.656 1.54699 20 0.993988 20H0.989014C0.437014 20 0 19.656 0 19.104V13.104C0 12.552 0.427004 12 0.979004 12H2.80899C4.43399 12 5.89001 13.216 5.97501 14.838ZM11 15.104C11 14.552 10.531 14 9.979 14H9V18H9.979C10.531 18 11 17.656 11 17.104V15.104ZM13 15V17C13 18.65 11.65 20 10 20H7.89499C7.39999 20 7 19.599 7 19.104V12.979C7 12.438 7.438 12 7.979 12H10C11.65 12 13 13.35 13 15ZM20 13.026V13.052C20 13.604 19.531 14 18.979 14H16V16H18.979C19.531 16 20 16.473 20 17.026V17.052C20 17.604 19.531 18 18.979 18H16V19.104C16 19.656 15.547 20 14.994 20H14.989C14.437 20 14 19.656 14 19.104V13.104C14 12.552 14.427 12 14.979 12H18.979C19.531 12 20 12.473 20 13.026Z" fill="#D30D15"/>
                                                                                </svg>
                                                                            </span>
                                                                        </a>))}
                        </div>
                        <div class="col">
                            <p class="profile--title">
                                МОЯ СЕМЬЯ
                            </p>
                            {this.state.family_data.filter((data) => { return !this.state.cabuserinfo.onecowner || data.uid !== this.state.cabuserinfo.onecowner }).map((data, index) => 
                                                                        <div class="profile--field" key={index}>
                                                                            <div class="profile--field__title">
                                                                                <span class="text">
                                                                                    {data.name}
                                                                                </span>
                                                                                {!this.state.can_edit && <span class="icon">
                                                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M10.9998 13.2917V15.125M6.4165 9.19307C6.84864 9.16667 7.38138 9.16667 8.0665 9.16667H13.9332C14.6183 9.16667 15.1511 9.16667 15.5832 9.19307M6.4165 9.19307C5.87721 9.22598 5.4946 9.30004 5.16803 9.46642C4.65058 9.73005 4.22989 10.1507 3.96624 10.6682C3.6665 11.2565 3.6665 12.0265 3.6665 13.5667V14.85C3.6665 16.3902 3.6665 17.1602 3.96624 17.7485C4.22989 18.266 4.65058 18.6866 5.16803 18.9503C5.75628 19.25 6.52636 19.25 8.0665 19.25H13.9332C15.4734 19.25 16.2434 19.25 16.8317 18.9503C17.3491 18.6866 17.7698 18.266 18.0334 17.7485C18.3332 17.1602 18.3332 16.3902 18.3332 14.85V13.5667C18.3332 12.0265 18.3332 11.2565 18.0334 10.6682C17.7698 10.1507 17.3491 9.73005 16.8317 9.46642C16.5051 9.30004 16.1224 9.22598 15.5832 9.19307M6.4165 9.19307V7.33333C6.4165 4.80203 8.46854 2.75 10.9998 2.75C13.5311 2.75 15.5832 4.80203 15.5832 7.33333V9.19307" stroke="#7E7E7E" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                </span>}
                                                                            </div>
                                                                            <div class="profile--field__value">
                                                                                <input type="text" class="input-transparent" value={data.birthdate} readonly></input>
                                                                            </div>
                                                                        </div>)}
                            <button data-form-id="add_memmber" class="button-primary" onClick={(e) => { this.setState({open_fam_member_modal: true, new_family_member_lname: "", new_family_member_fname: "",
                                                                                new_family_member_sname: "", new_family_member_birthdate: "", new_family_member_sex: "", confirmation_for_pd: false,
                                                                                less_than_fifteen: true, new_family_member_pass_ser: "", new_family_member_pass_num: "", new_family_member_pass_date: "",
                                                                                new_family_member_pass_code: "", new_family_member_pass_unit: "", new_family_member_addr_cn: "",
                                                                                new_family_member_addr_rg: "", new_family_member_addr_idx: "", new_family_member_addr_tn: "",
                                                                                new_family_member_addr_st: "", new_family_member_addr_hs: "", new_family_member_addr_cp: "",
                                                                                new_family_member_addr_ft: ""}); const formId = e.target.dataset.formId;
                                                                                const form = document.getElementById(formId); if(form) form.classList.add('open');}}>
                                Добавить члена семьи
                            </button>
                        </div>
                    </div>
                </div>
                <div id="add_memmber" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="tabs">
                            <p class="modal-content--title">
                                Добавление члена семьи
                            </p>
                            <div class="tab-control">
                                <div class="tab-control--item open">
                                    <span>
                                        До 15 лет
                                    </span>
                                </div>
                                <div class="tab-control--item">
                                    <span>
                                        Более 15 лет
                                    </span>
                                </div>
                            </div>
                            <div class="tab-content">
                                <div class="tab-content--item open">
                                    <div class="form-group">
                                        <input id="lastname" name="lastname" class="form-input" type="text" placeholder="Введите вашу фамилию" value={this.state.new_family_member_lname} onChange={this.newFamMemberLnameHandler}></input>
                                        <label for="lastname" class="form-label">Фамилия</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="name" name="name" class="form-input" type="text" placeholder="Введите ваше имя" value={this.state.new_family_member_fname} onChange={this.newFamMemberFnameHandler}></input>
                                        <label for="name" class="form-label">Имя</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="fathername" name="fathername" class="form-input" type="text" placeholder="Введите ваше отчество" value={this.state.new_family_member_sname} onChange={this.newFamMemberSnameHandler}></input>
                                        <label for="fathername" class="form-label">Отчество</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="birthday" name="birthday" class="form-input date-mask" type="date" placeholder="Введите" value={this.state.new_family_member_birthdate} onChange={(e) => { this.setState({new_family_member_birthdate: e.target.value}); }}></input>
                                        <label for="birthday" class="form-label">Дата рождения</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <select name="sex" value={this.state.new_family_member_sex} onChange={(e) => this.setState({new_family_member_sex: e.target.value})}>
                                            <option value="">Пол</option>
                                            <option value="М">М</option>
                                            <option value="Ж">Ж</option>
                                        </select>
                                    </div>
                                    <div class="form-checkbox">
                                        <input id="agreement" type="checkbox" name="agreement" onChange={(e) => this.setState({ confirmation_for_pd: !this.state.confirmation_for_pd })} checked={this.state.confirmation_for_pd}></input>
                                        <label for="agreement" class="form-checkbox--label">
                                            Я даю согласие на обработку моих персональных данных и принимаю пользовательское соглашение
                                        </label>
                                    </div>
                                    <input type="submit" class="button-primary" data-form-id="add_memmber" value="Добавить члена семьи" onClick={ this.submitNewFamMemberHandler }></input>
                                </div>
                                <div class="tab-content--item">
                                    <div class="form-group">
                                        <input id="lastname2" name="lastname2" class="form-input" type="text" placeholder="Введите вашу фамилию" value={this.state.new_family_member_lname} onChange={this.newFamMemberLnameHandler}></input>
                                        <label for="lastname2" class="form-label">Фамилия</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="name2" name="name2" class="form-input" type="text" placeholder="Введите ваше имя" value={this.state.new_family_member_fname} onChange={this.newFamMemberFnameHandler}></input>
                                        <label for="name2" class="form-label">Имя</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="fathername2" name="fathername2" class="form-input" type="text" placeholder="Введите ваше отчество" value={this.state.new_family_member_sname} onChange={this.newFamMemberSnameHandler}></input>
                                        <label for="fathername2" class="form-label">Отчество</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <input id="birthday2" name="birthday2" class="form-input date-mask" type="date" placeholder="Введите" value={this.state.new_family_member_birthdate} onChange={(e) => { this.setState({new_family_member_birthdate: e.target.value}); }}></input>
                                        <label for="birthday2" class="form-label">Дата рождения</label>
                                        <span class="form-clear">
                                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                    </div>
                                    <div class="form-group">
                                        <select name="sex2" value={this.state.new_family_member_sex} onChange={(e) => this.setState({new_family_member_sex: e.target.value})}>
                                            <option value="">Пол</option>
                                            <option value="М">М</option>
                                            <option value="Ж">Ж</option>
                                        </select>
                                    </div>
                                    <div class="form-unification">
                                        <div class="form-unification--title">
                                            Паспорт                                                                                                         
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="serial">Серия</label>
                                            <input id="serial" type="number" class="input-transparent pass-serial-mask" placeholder="----" value={this.state.new_family_member_pass_ser} onChange={(e) => this.setState({new_family_member_pass_ser: e.target.value})}></input>
                                            <label class="simple-label" for="number">Номер</label>
                                            <input id="number" type="number" class="input-transparent pass-number-mask" placeholder="------" value={this.state.new_family_member_pass_num} onChange={(e) => this.setState({new_family_member_pass_num: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="date-put">Дата выдачи</label>
                                            <input id="date-put" type="text" class="input-transparent date-mask" placeholder="--.--.--" value={this.state.new_family_member_pass_date} onChange={(e) => this.setState({new_family_member_pass_date: e.target.value})}></input>
                                            <label class="simple-label" for="code">Код подразделения</label>
                                            <input id="code" type="text" class="input-transparent department-code-mask" placeholder="--- ---" value={this.state.new_family_member_pass_code} onChange={(e) => this.setState({new_family_member_pass_code: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="issued-by">Кем выдан</label>
                                            <input id="issued-by" type="text" class="input-simple" value={this.state.new_family_member_pass_unit} onChange={(e) => this.setState({new_family_member_pass_unit: e.target.value})}></input>
                                        </div>
                                    </div>
                                    <div class="form-unification">
                                        <div class="form-unification--title">
                                            Адрес прописки                                                                                              
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="copuntry">Страна</label>
                                            <input id="copuntry" type="text" class="input-simple" value={this.state.new_family_member_addr_cn} onChange={(e) => this.setState({new_family_member_addr_cn: e.target.value})}></input>
                                            <label class="simple-label" for="region">Область</label>
                                            <input id="region" type="text" class="input-simple" value={this.state.new_family_member_addr_rg} onChange={(e) => this.setState({new_family_member_addr_rg: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="index">Индекс</label>
                                            <input id="index" type="text" class="input-transparent index-mask" placeholder="------" value={this.state.new_family_member_addr_idx} onChange={(e) => this.setState({new_family_member_addr_idx: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="city">Город/населенный пункт</label>
                                            <input id="city" type="text" class="input-simple" value={this.state.new_family_member_addr_tn} onChange={(e) => this.setState({new_family_member_addr_tn: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="street">Улица</label>
                                            <input id="street" type="text" class="input-simple" value={this.state.new_family_member_addr_st} onChange={(e) => this.setState({new_family_member_addr_st: e.target.value})}></input>
                                        </div>
                                        <div class="form-unification--row">
                                            <label class="simple-label" for="house">Дом</label>
                                            <input id="house" type="text" class="input-simple" value={this.state.new_family_member_addr_hs} onChange={(e) => this.setState({new_family_member_addr_hs: e.target.value})}></input>
                                            <label class="simple-label" for="house">Корпус</label>
                                            <input id="house" type="text" class="input-simple" value={this.state.new_family_member_addr_cp} onChange={(e) => this.setState({new_family_member_addr_cp: e.target.value})}></input>
                                            <label class="simple-label" for="flat">Квартира</label>
                                            <input id="flat" type="text" class="input-simple" value={this.state.new_family_member_addr_ft} onChange={(e) => this.setState({new_family_member_addr_ft: e.target.value})}></input>
                                        </div>
                                    </div>
                                    <div class="form-checkbox">
                                        <input id="agreement1" type="checkbox" name="agreement"onChange={(e) => this.setState({ confirmation_for_pd: !this.state.confirmation_for_pd })} checked={this.state.confirmation_for_pd}></input>
                                        <label for="agreement1" class="form-checkbox--label">
                                            Я даю согласие на обработку моих персональных данных и принимаю пользовательское соглашение
                                        </label>
                                    </div>
                                    <input type="submit" class="button-primary" value="Добавить члена семьи" data-form-id="add_memmber" onClick={ this.submitNewFamMemberHandler }></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="show_addr_fc" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div>
                            <p class="form-title">Адрес проживания</p>
                            <div class="form-group">
                                <input id="fc-index" name="fc-index" class="form-input" type="text" placeholder="Введите почтовый индекс" value={this.state.addr_fc_temp && this.state.addr_fc_temp.idx ? this.state.addr_fc_temp.idx : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.idx = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-index" class="form-label">Индекс</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-country" name="fc-country" class="form-input" type="text" placeholder="Введите страну" value={this.state.addr_fc_temp && this.state.addr_fc_temp.cn ? this.state.addr_fc_temp.cn : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.cn = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-country" class="form-label">Страна</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-region" name="fc-region" class="form-input" type="text" placeholder="Введите область" value={this.state.addr_fc_temp && this.state.addr_fc_temp.rg ? this.state.addr_fc_temp.rg : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.rg = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-region" class="form-label">Область</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-city" name="fc-city" class="form-input" type="text" placeholder="Введите город" value={this.state.addr_fc_temp && this.state.addr_fc_temp.tn ? this.state.addr_fc_temp.tn : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.tn = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-city" class="form-label">Город</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-street" name="fc-street" class="form-input" type="text" placeholder="Введите улицу" value={this.state.addr_fc_temp && this.state.addr_fc_temp.st ? this.state.addr_fc_temp.st : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.st = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-street" class="form-label">Улица</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-house" name="fc-house" class="form-input" type="text" placeholder="Введите номер дома" value={this.state.addr_fc_temp && this.state.addr_fc_temp.hs ? this.state.addr_fc_temp.hs : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.hs = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-house" class="form-label">Дом</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-unit" name="fc-unit" class="form-input" type="text" placeholder="Введите номер корпуса" value={this.state.addr_fc_temp && this.state.addr_fc_temp.cp ? this.state.addr_fc_temp.cp : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.cp = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-unit" class="form-label">Корпус</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="fc-flat" name="fc-flat" class="form-input" type="text" placeholder="Введите номер квартиры" value={this.state.addr_fc_temp && this.state.addr_fc_temp.ft ? this.state.addr_fc_temp.ft : ""} onChange={(e) => {let addr_fc_local = this.state.addr_fc_temp; addr_fc_local.ft = e.target.value; this.setState({addr_fc_temp: addr_fc_local})}}></input>
                                <label for="fc-flat" class="form-label">Квартира</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="submit" class="button-primary" value="Сохранить" data-form-id="show_addr_fc" onClick={ this.handleAddrFcChanging }></input>
                        </div>
                    </div>
                </div>
                <div id="show_addr_lg" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div>
                            <p class="form-title">Адрес регистрации</p>
                            <div class="form-group">
                                <input id="lg-index" name="lg-index" class="form-input" type="text" placeholder="Введите почтовый индекс" value={this.state.addr_lg_temp && this.state.addr_lg_temp.idx ? this.state.addr_lg_temp.idx : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.idx = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-index" class="form-label">Индекс</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-country" name="lg-country" class="form-input" type="text" placeholder="Введите страну" value={this.state.addr_lg_temp && this.state.addr_lg_temp.cn ? this.state.addr_lg_temp.cn : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.cn = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-country" class="form-label">Страна</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-region" name="lg-region" class="form-input" type="text" placeholder="Введите область" value={this.state.addr_lg_temp && this.state.addr_lg_temp.rg ? this.state.addr_lg_temp.rg : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.rg = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-region" class="form-label">Область</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-city" name="lg-city" class="form-input" type="text" placeholder="Введите город" value={this.state.addr_lg_temp && this.state.addr_lg_temp.tn ? this.state.addr_lg_temp.tn : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.tn = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-city" class="form-label">Город</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-street" name="lg-street" class="form-input" type="text" placeholder="Введите улицу" value={this.state.addr_lg_temp && this.state.addr_lg_temp.st ? this.state.addr_lg_temp.st : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.st = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-street" class="form-label">Улица</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-house" name="lg-house" class="form-input" type="text" placeholder="Введите номер дома" value={this.state.addr_lg_temp && this.state.addr_lg_temp.hs ? this.state.addr_lg_temp.hs : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.hs = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-house" class="form-label">Дом</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-unit" name="lg-unit" class="form-input" type="text" placeholder="Введите номер корпуса" value={this.state.addr_lg_temp && this.state.addr_lg_temp.cp ? this.state.addr_lg_temp.cp : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.cp = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-unit" class="form-label">Корпус</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="lg-flat" name="lg-flat" class="form-input" type="text" placeholder="Введите номер квартиры" value={this.state.addr_lg_temp && this.state.addr_lg_temp.ft ? this.state.addr_lg_temp.ft : ""} onChange={(e) => {let addr_lg_local = this.state.addr_lg_temp; addr_lg_local.ft = e.target.value; this.setState({addr_lg_temp: addr_lg_local})}}></input>
                                <label for="lg-flat" class="form-label">Квартира</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="submit" class="button-primary" value="Сохранить" data-form-id="show_addr_lg" onClick={ this.handleAddrLgChanging }></input>
                        </div>
                    </div>
                </div>
                <div id="show_passport" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div>
                            <p class="form-title">Паспорт</p>
                            <div class="form-group">
                                <input id="pass-ser" name="pass-ser" class="form-input" type="number" placeholder="Введите серию" value={this.state.passport_temp && this.state.passport_temp.ser ? this.state.passport_temp.ser : ""} onChange={(e) => {let passport_local = this.state.passport_temp; passport_local.ser = e.target.value; this.setState({passport_temp: passport_local})}}></input>
                                <label for="pass-ser" class="form-label">Серия</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="pass-num" name="pass-num" class="form-input" type="number" placeholder="Введите номер" value={this.state.passport_temp && this.state.passport_temp.num ? this.state.passport_temp.num : ""} onChange={(e) => {let passport_local = this.state.passport_temp; passport_local.num = e.target.value; this.setState({passport_temp: passport_local})}}></input>
                                <label for="pass-num" class="form-label">Номер</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="pass-date" name="pass-date" class="form-input" type="date" placeholder="Введите дату выдачи" value={this.state.passport_temp && this.state.passport_temp.udate ? this.state.passport_temp.udate.replaceAll('.', '-') : ""} onChange={(e) => {let passport_local = this.state.passport_temp; passport_local.udate = e.target.value; this.setState({passport_temp: passport_local})}}></input>
                                <label for="pass-date" class="form-label">Дата выдачи</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="pass-code" name="pass-code" class="form-input" type="text" placeholder="Введите код подразделения" value={this.state.passport_temp && this.state.passport_temp.ucode ? this.state.passport_temp.ucode : ""} onChange={(e) => {let passport_local = this.state.passport_temp; passport_local.ucode = e.target.value; this.setState({passport_temp: passport_local})}}></input>
                                <label for="pass-code" class="form-label">Код подразделения</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="pass-unit" name="pass-unit" class="form-input" type="text" placeholder="Введите название организации" value={this.state.passport_temp && this.state.passport_temp.unit ? this.state.passport_temp.unit : ""} onChange={(e) => {let passport_local = this.state.passport_temp; passport_local.unit = e.target.value; this.setState({passport_temp: passport_local})}}></input>
                                <label for="pass-unit" class="form-label">Кем выдан</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="submit" class="button-primary" value="Сохранить" data-form-id="show_passport" onClick={ this.handlePassportChanging }></input>
                        </div>
                    </div>
                </div>
                <div id="change_names" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p class="modal-content--title">
                            Изменение ФИО
                        </p>
                        <div>
                            <div class="form-group">
                                <input id="newlastname" name="newlastname" class="form-input" type="text" placeholder="Введите вашу фамилию" value={this.state.new_lname} onChange={this.newLnameHandler}></input>
                                <label for="newlastname" class="form-label">Фамилия</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="newname" name="newname" class="form-input" type="text" placeholder="Введите ваше имя" value={this.state.new_fname} onChange={this.newFnameHandler}></input>
                                <label for="newname" class="form-label">Имя</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-group">
                                <input id="newfathername" name="newfathername" class="form-input" type="text" placeholder="Введите ваше отчество" value={this.state.new_sname} onChange={this.newSnameHandler}></input>
                                <label for="newfathername" class="form-label">Отчество</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <input type="submit" class="button-primary" value="Сохранить" onClick={ this.handleNamesChanging }></input>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    formAddress = (addr) => {
        let addrstr = (addr.idx ? (addr.idx + ", ") : "") +
                      (addr.cn ? (addr.cn + ", ") : "") +
                      (addr.rg ? (addr.rg + ", ") : "") +
                      (addr.tn ? ("г. " + addr.tn + ", ") : "") +
                      (addr.st ? ("ул. " + addr.st + ", ") : "") +
                      (addr.hs ? ("д. " + addr.hs + ", ") : "") +
                      (addr.cp ? ("кр. " + addr.cp + ", ") : "") +
                      (addr.ft ? ("кв. " + addr.ft + ", ") : "");
        if(addrstr >= 2)
            addrstr = addrstr.slice(0, addrstr.length - 2);
        return addrstr;
    }

    handleAddrFc = (e) => {
        //Если редактирование запрещено
        if(!this.state.can_edit)
        {
            //Устанавливаем ошибку и прекращаем обработку
            if(this.state.error_messages.indexOf("Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники"]);
            return;
        }

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Открываем модальное окно
        if(form) {
            form.classList.add('open')
        }

        //Меняем состояние
        let obj = ((this.state.onec.addrfc && this.state.onec.addrfc.length) ? { ...this.state.onec.addrfc[0] } : {});
        this.setState({open_addr_fc_modal: true, addr_fc_temp: obj });
    }

    handleAddrFcChanging = (e) => {
        e.preventDefault();

        //Формируем единую строку адреса
        let addrstr = this.formAddress(this.state.addr_fc_temp);
        //Если она пуста
        if(addrstr === 0)
        {
            //Устанавливаем ошибку и прекращаем обработку
            if(this.state.error_messages.indexOf("Адрес проживания не заполнен") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Адрес проживания не заполнен"]);
            return;
        }

        //Делаем копию объекта onec
        let onec = {...this.state.onec};
        //Переносим в него временный объект адреса и единую строку адреса
        onec.addrfc[0] = this.state.addr_fc_temp;
        onec.addrfcstr = addrstr

        //Отправляем данные на сервер
        this.state.send_onec_set_callback(onec, this.state.cabuserinfo);

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Закрываем модальное окно
        if(form) {
            form.classList.remove('open')
        }
    }

    handleAddrLg = (e) => {
        //Если редактирование запрещено
        if(!this.state.can_edit)
        {
            //Устанавливаем ошибку и прекращаем обработку
            if(this.state.error_messages.indexOf("Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники"]);
            return;
        }

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Открываем модальное окно
        if(form) {
            form.classList.add('open')
        }

        //Меняем состояние
        let obj = ((this.state.onec.addrlg && this.state.onec.addrlg.length) ? { ...this.state.onec.addrlg[0] } : {});
        this.setState({open_addr_lg_modal: true, addr_lg_temp: obj });
    }

    handleAddrLgChanging = (e) => {
        e.preventDefault();

        //Формируем единую строку адреса
        let addrstr = this.formAddress(this.state.addr_lg_temp);
        //Если она пуста
        if(addrstr === 0)
        {
            //Устанавливаем ошибку и прекращаем обработку
            if(this.state.error_messages.indexOf("Адрес регистрации не заполнен") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Адрес регистрации не заполнен"]);
            return;
        }

        //Делаем копию объекта onec
        let onec = {...this.state.onec};
        //Переносим в него временный объект адреса и единую строку адреса
        onec.addrlg[0] = this.state.addr_lg_temp;
        onec.addrlgstr = addrstr

        //Отправляем данные на сервер
        this.state.send_onec_set_callback(onec, this.state.cabuserinfo);

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Закрываем модальное окно
        if(form) {
            form.classList.remove('open')
        }
    }

    handlePassport = (e) => {
        //Если редактирование запрещено
        if(!this.state.can_edit)
        {
            //Устанавливаем ошибку и прекращаем обработку
            if(this.state.error_messages.indexOf("Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Редактирование информации невозможно. Для внесения изменений обратитесь на стойку администратора клиники"]);
            return;
        }

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Открываем модальное окно
        if(form) {
            form.classList.add('open')
        }

        //Меняем состояние
        let obj = ((this.state.onec.pass && this.state.onec.pass.length) ? { ...this.state.onec.pass[0] } : {});
        this.setState({ open_passport_modal: true, passport_temp: obj });
    }

    handlePassportChanging = (e) => {
        e.preventDefault();

        //Делаем копию объекта onec
        let onec = {...this.state.onec};
        if(onec.pass == undefined)
            onec.pass = [];
        //Переносим в него временный объект паспорта
        onec.pass[0] = this.state.passport_temp;

        //Отправляем данные на сервер
        this.state.send_onec_set_callback(onec, this.state.cabuserinfo);

        //Получаем id искомого элемента
        const formId = e.target.dataset.formId;
        //Получаем сам элемент
        const form = document.getElementById(formId)
        //Закрываем модальное окно
        if(form) {
            form.classList.remove('open')
        }
    }

    base64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    openDocument = (e) => {
        //Устанавливаем анимацию загрузки
        this.setState({loading: true});

        //Запрашиваем указанный документ
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({ "uid": e.currentTarget.dataset.caid, "ftype": "3" })
        };
        fetch('/api/getfile/', request)
            .then(response => response.json())
            .then(data => {
                //Сбрасываем анимацию загрузки
                this.setState({ loading: false });

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                if(data.result && data.body.binaryData && data.body.ext)
                {
                    //Переводим base64 в blob
                    let blob = this.base64toBlob(data.body.binaryData, "application/" + data.body.ext);

                    //Получаем url
                    let doc_url = URL.createObjectURL(blob);

                    //Скачаваем файл через создание элемента 'a'
                    let file_link = document.createElement('a');
                    file_link.href = doc_url;
                    file_link.download = doc_url.split("/").pop() + "." + data.body.ext;
                    file_link.click();
                }
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    deleteFamMember = (e) => {
        e.preventDefault();

        //Удаляем uid из массива onec_uids
        let cabuserinfo = this.state.cabuserinfo;
        for(let key in cabuserinfo.onec_uids)
            if(cabuserinfo.onec_uids[key] === e.currentTarget.dataset.uid)
                delete cabuserinfo.onec_uids[key];
        this.setState({cabuserinfo: cabuserinfo});

        //Отправляем данные на сервер
        this.state.send_onec_set_callback(this.state.onec, this.state.cabuserinfo);
    }

    newFamMemberLnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_family_member_lname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    newFamMemberFnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_family_member_fname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    newFamMemberSnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_family_member_sname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    newLnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_lname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    newFnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_fname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    newSnameHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 15)
        {
            //Отрабатываем callback через setState и выходим
            this.setState({ }, save_caret_callback);
            return;
        }
        for(let symbol of e.target.value)
            if((symbol < 'А' || symbol > 'я') && symbol != '-')
            {
                //Отрабатываем callback через setState и выходим
                this.setState({ }, save_caret_callback);
                return;
            }

        //Сохраняем полученное значение, выставляя первую букву прописной (а также букву после дефиса), а остальные строчными
        this.setState({ new_sname: e.target.value.toLowerCase().replace(/(^|\-)[а-яё]/gi, (match) => match.toUpperCase()) });
    }

    handleNamesChanging = (e) => {
        e.preventDefault();

        //Получаем список ошибок валидации
        let errors = this.validateNewFamMemberNames(this.state.new_lname, [], "Фамилия слишком короткая");
        errors = this.validateNewFamMemberNames(this.state.new_fname, errors, "Имя слишком короткое");
        errors = this.validateNewFamMemberNames(this.state.new_sname, errors, "Отчество слишком короткое");

        this.state.update_error_messages_callback(errors);

        //Если ошибок нет, можно отправлять данные на сервер
        if(errors.length === 0)
        {
            //Делаем копию объекта onec
            let onec = {...this.state.onec};
            //Переносим в него временные объекты
            onec.lname = this.state.new_lname;
            onec.fname = this.state.new_fname;
            onec.sname = this.state.new_sname;

            //Отправляем данные на сервер
            this.state.send_onec_set_callback(onec, this.state.cabuserinfo);

            //Закрываем модальное окно
            const form = document.getElementById("change_names")
            if(form) {
                form.classList.remove('open')
            }
        }
    }

    validateNewFamMemberNames = (names, errors, description) => {
        //Проверяем ФИО на длину
        if(names.length < 2)
            return [...errors, description];

        return errors;
    }

    validateNewFamMemberBirthdate = (birthdate, errors) => {
        if(birthdate === "")
            return [...errors, "Не указана дата рождения"];

        //Сравниваем дату с текущей, чтобы отсечь будущие
        let today = new Date().getTime();
        birthdate = birthdate.split("-");
        let birthdate_time = new Date(birthdate[0], birthdate[1] - 1, birthdate[2]).getTime();

        if(today - birthdate_time < 0)
            return [...errors, "Дата рождения в будущем"];

        /* if(!this.state.less_than_fifteen && new Date().getFullYear() - birthdate[0] <= 15)
            return [...errors, "Дата рождения не попадает в диапазон \"Более 15 лет\""];
        else if(this.state.less_than_fifteen && new Date().getFullYear() - birthdate[0] >= 15)
            return [...errors, "Дата рождения не попадает в диапазон \"До 15 лет\""]; */

        return errors;
    }

    submitNewFamMemberHandler = (e) => {
        e.preventDefault();

        //Получаем список ошибок валидации
        let errors = this.validateNewFamMemberNames(this.state.new_family_member_lname, [], "Фамилия слишком короткая");
        errors = this.validateNewFamMemberNames(this.state.new_family_member_fname, errors, "Имя слишком короткое");
        errors = this.validateNewFamMemberNames(this.state.new_family_member_sname, errors, "Отчество слишком короткое");
        errors = this.validateNewFamMemberBirthdate(this.state.new_family_member_birthdate, errors);
        if(!this.state.less_than_fifteen && (this.state.new_family_member_pass_ser.length === 0 || this.state.new_family_member_pass_num.length === 0 ||
                    this.state.new_family_member_pass_date.length === 0 || this.state.new_family_member_pass_code.length === 0 || this.state.new_family_member_pass_unit.length === 0))
            errors = [...errors, "Не введены паспортные данные"]
        if(!this.state.confirmation_for_pd)
            errors = [...errors, "Необходимо согласие на обработку персональных данных"]
        if(!this.state.less_than_fifteen && (this.state.new_family_member_addr_idx.length === 0 || this.state.new_family_member_addr_cn.length === 0 ||
                    this.state.new_family_member_addr_rg.length === 0 || this.state.new_family_member_addr_tn.length === 0 || this.state.new_family_member_addr_st.length === 0 ||
                    this.state.new_family_member_addr_hs.length === 0))
            errors = [...errors, "Не введен адрес прописки"]

        this.state.update_error_messages_callback(errors);

        //Если ошибок нет, можно отправлять данные на сервер
        if(errors.length === 0)
        {
            //Устанавливаем анимацию загрузки
            this.setState({ loading: true });

            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                body: JSON.stringify({ "uid": "",
                                        "fname": this.state.new_family_member_fname,
                                        "lname": this.state.new_family_member_lname,
                                        "sname": this.state.new_family_member_sname,
                                        "bdate": (this.state.new_family_member_birthdate && this.state.new_family_member_birthdate.length >= 10) ?
                                                  (this.state.new_family_member_birthdate[0] + this.state.new_family_member_birthdate[1] + this.state.new_family_member_birthdate[2] +
                                                   this.state.new_family_member_birthdate[3] + this.state.new_family_member_birthdate[5] + this.state.new_family_member_birthdate[6] +
                                                   this.state.new_family_member_birthdate[8] + this.state.new_family_member_birthdate[9] + "000000") : "",
                                        "sex": this.state.new_family_member_sex, "tel": this.state.onec.tel.replaceAll("-", ""), "telw": "", "lrid": this.state.cabuserinfo.onecowner ? this.state.cabuserinfo.onecowner : "",
                                        "addrlgstr": this.formAddress({"idx": this.state.new_family_member_addr_idx, "cn": this.state.new_family_member_addr_cn,
                                                                       "rg": this.state.new_family_member_addr_rg, "tn": this.state.new_family_member_addr_tn,
                                                                       "st": this.state.new_family_member_addr_st, "hs": this.state.new_family_member_addr_hs,
                                                                       "cp": this.state.new_family_member_addr_cp, "ft": this.state.new_family_member_addr_ft}),
                                        "addrlg": { "idx": this.state.new_family_member_addr_idx, "cn": this.state.new_family_member_addr_cn, "rg": this.state.new_family_member_addr_rg,
                                                    "tn": this.state.new_family_member_addr_tn, "st": this.state.new_family_member_addr_st, "hs": this.state.new_family_member_addr_hs,
                                                    "cp": this.state.new_family_member_addr_cp, "ft": this.state.new_family_member_addr_ft
                                                  },
                                        "addrfcstr": this.state.onec.addrfcstr ? this.state.onec.addrfcstr : "",
                                        "addrfc": { "idx": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].idx) ? this.state.onec.addrfc[0].idx : "",
                                                    "cn": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].cn) ? this.state.onec.addrfc[0].cn : "",
                                                    "rg": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].rg) ? this.state.onec.addrfc[0].rg : "",
                                                    "tn": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].tn) ? this.state.onec.addrfc[0].tn : "",
                                                    "st": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].st) ? this.state.onec.addrfc[0].st : "",
                                                    "hs": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].hs) ? this.state.onec.addrfc[0].hs : "",
                                                    "cp": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].cp) ? this.state.onec.addrfc[0].cp : "",
                                                    "ft": (this.state.onec.addrfc && this.state.onec.addrfc.length > 0 && this.state.onec.addrfc[0].ft) ? this.state.onec.addrfc[0].ft : ""
                                                  },
                                        "isid": "", "adid": "", "respid": "", "cmt": "",
                                        "pass": { "ser": this.state.new_family_member_pass_ser, "num": this.state.new_family_member_pass_num, "udate": this.state.new_family_member_pass_date,
                                                  "ucode": this.state.new_family_member_pass_code, "unit": this.state.new_family_member_pass_unit }, "vrf": "0"
                                    })

            };
            fetch('/api/SetRelativeInfo', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    this.setState({loading: false});

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(!data.result)
                    {
                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                        {
                            localStorage.removeItem('accessToken');
                            window.location = "/login";
                        }
                        else
                            if(this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                    }
                    else
                    {
                        //Переспрашиваем и обрабатываем данные пользователя
                        this.state.get_user_info_callback(() => {}, (e) => this.setState(e));

                        //Получаем id искомого элемента
                        const formId = e.target.dataset.formId;
                        //Получаем сам элемент
                        const form = document.getElementById(formId)
                        //Закрываем модальное окно
                        if(form) {
                            form.classList.remove('open')
                        }
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    this.setState({loading: false});
                    //Устанавливаем ошибку
                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                });

            //Закрываем модальное окно
            this.setState({ open_fam_member_modal: false });
        }
    }
}

import React from 'react';
import '../assets/css/main.css';
import '../assets/css/nice-select2.css';
import '../assets/css/swiper-bundle.min.css';
import PhoneInput from './../Inputs/PhoneInput';
import {Notice, initForms, initLayoutScripts} from '../assets/js/notice.js'
import special_offer_logo from '../assets/img/special-offer.png'

//Перечисление секций, между которыми переключается страница
const Sections = {
	PromotionSelection: 1,
	PromotionDescription: 2,
	Summary: 3
}

export default class Promotions extends React.Component {
    constructor(props) {
        super(props);

        this.state = { section: Sections.PromotionSelection, loading: false, open_modal: false, promotions: [], current_promotion_index: -1, error_messages: props.error_messages,
                        update_error_messages_callback: props.update_error_messages_callback, fio: "", phone: "", email: "", router: props.router, need_to_init: false };

        //Если пользователь не авторизован, перенаправляем его на страницу входа
        if(!localStorage.getItem('accessToken'))
            window.location = "/login";
    }

    componentDidUpdate(prevProps) {
        //Если есть ошибки, выводим всплывающее окно с ними
        if(this.state.error_messages.length > 0)
            Notice.show(this.state.error_messages.join('<br/>'));

        if(prevProps.error_messages !== this.props.error_messages)
            this.setState({error_messages: this.props.error_messages});
        if(prevProps.update_error_messages_callback !== this.props.update_error_messages_callback)
            this.setState({update_error_messages_callback: this.props.update_error_messages_callback});
        if(prevProps.router !== this.props.router)
            this.setState({router: this.props.router});

        if(this.state.need_to_init)
        {
            //Инициализируем скрипты от верстальщиков
            initLayoutScripts();
            Notice.setHideCallback(() => { this.setState({error_messages: []}); this.state.update_error_messages_callback([]); });
            this.setState({need_to_init: false});
        }
    }

    componentDidMount() {
        //Инициализируем скрипты от верстальщиков
        initLayoutScripts();
        Notice.setHideCallback(() => {this.setState({error_messages: []})});

        //Включаем анимацию загрузки
        /* this.setState({ loading: true });

        //Счетчик обработанных запросов
        const requests_to_fetch = 1;
        let requests_processed = 0;

        //Запрашиваем список анализов
        fetch('/api/promotions', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ promotions: [].concat(...Object.values(data.body)) });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки, если все запросы обработаны
                if(++requests_processed >= requests_to_fetch)
                    this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            }); */
    }

    render() {
        if(this.state.section === Sections.PromotionSelection)
        {
            return (
                <>
                    {this.state.loading &&
                    <div class="pre-loader">
                        <div class="loader-spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    }

                    <h1>
                        Специальные предложения
                    </h1>
                    <div class="special-offers">
                        {this.state.promotions.map((promotion, index) =>
                        <div class="special-offers--item" key={index}>
                            <div style={{backgroundImage: `url(${promotion.image})`}} class="special-offers--item__image">
                                <span class="discount">
                                    -15%
                                </span>
                            </div>
                            <div class="special-offers--item__content">
                                <p class="title">
                                    <span>{promotion.title1}</span>
                                    {promotion.title2}
                                </p>
                                <div class="price">
                                    <p class="name">Цена со скидкой:</p>
                                    <p class="value">{promotion.price} руб.</p>
                                </div>
                                <div class="description">
                                    {promotion.description}
                                </div>
                                <a class="link" href="#" data-index={index} onClick={this.toDescription}>Подробнее</a>
                            </div>
                        </div>
                        )}
                    </div>
                    {this.state.promotions.length === 0 &&
                    <div>
                        Специальные предложения отсутствуют, загляните позже
                    </div>
                    }
                </>
            );
        }
        else if(this.state.section === Sections.PromotionDescription)
        {
            return (
                <>
                    <div class="special-offers-detail">
                        <div class="special-offers-detail--title">
                            <h1>
                                <span>{this.state.promotions[this.state.current_promotion_index].title1}</span>
                                {this.state.promotions[this.state.current_promotion_index].title2}
                            </h1>
                            <div class="price">
                                <p class="name">
                                    Цена со скидкой:
                                </p>
                                <p class="value">
                                    {this.state.promotions[this.state.current_promotion_index].price} руб.
                                </p>
                            </div>
                        </div>
                        <p>{this.state.promotions[this.state.current_promotion_index].full_description}</p>
                        <div class="buttons">
                            <div class="col">
                                <button data-form-id="service" class="button-primary" onClick={(e) => { const formId = e.target.dataset.formId; const form = document.getElementById(formId); if(form) form.classList.add('open'); this.setState({ open_modal: true }) }}>Записаться</button>
                            </div>
                            <div class="col">
                                <button class="button-lightgray" onClick={this.toPromotions}>Назад</button>
                            </div>
                        </div>
                    </div>
                    <div id="service" class="modal">
                        <div class="modal-content">
                            <div class="modal-content--close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <p class="modal-content--title">
                                Запись на услугу
                            </p>
                            <p>
                                Для записи на услугу, заполните поля ниже, и мы вам перезвоним для уточнения удобного для Вас времени посещения.
                            </p>
                            <div class="form-group">
                                <input id="fullname" name="lastname" class="form-input" type="text" placeholder="Введите ваше ФИО" value={this.state.fio} onChange={(e) => {this.setState({ fio: e.target.value });}}></input>
                                <label for="fullname" class="form-label">Ваше ФИО</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <PhoneInput phone_update_callback={(value) => {this.setState({ phone: value });}} auto_focus={false}/>
                            <div class="form-group">
                                <input id="email" name="email" class="form-input" type="email" placeholder="Введите электронную почту" value={this.state.email} onChange={(e) => {this.setState({ email: e.target.value });}}></input>
                                <label for="email" class="form-label">Электронная почта</label>
                                <span class="form-clear">
                                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" stroke-width="0.909137" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="form-checkbox">
                                <input id="agreement" type="checkbox" name="agreement" onChange={(e) => this.setState({ confirmation_for_pd: !this.state.confirmation_for_pd })} checked={this.state.confirmation_for_pd}></input>
                                <label for="agreement" class="form-checkbox--label">
                                    Я даю согласие на обработку моих персональных данных и принимаю пользовательское соглашение
                                </label>
                            </div>
                            <input type="submit" class="button-primary" value="Оформить запись" onClick={ this.submitAppointment }></input>
                        </div>
                    </div>
                </>
            );
        }
        else
        {
            return (
                <>
                    <h1>Заявка оставлена</h1>
                    <div class="col buttons">
                        <a href="#" class="button-lightgray back" onClick={(e) => this.state.router.navigate("/profile")}>Вернуться в профиль</a>
                    </div>
                </>
            );
        }
    }

    toDescription = (e) => {
        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        this.setState({ section: Sections.PromotionDescription, current_promotion_index: e.currentTarget.dataset.index, need_to_init: true });
    }

    toPromotions = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        this.setState({ section: Sections.PromotionSelection });
    }

    validatePhoneNum = (errors) => {
        //Проверяем номер телефона на длину
        if(this.state.phone.length !== 10)
            return [...errors, "Неверная длина номера телефона"];

        //Проверяем номер телефона на наличие нечисловых символов
        for(let symbol of this.state.phone)
        {
            if(symbol < '0' || symbol > '9')
                return [...errors, "Номер телефона содержит неопознанные символы"];
        }

        return errors;
    }

    validateEmail = (email, errors) => {
        if(! /\S+@\S+\.\S+/.test(email))
            return [...errors, "Email некорректен"];

        return errors;
    }

    submitAppointment = (e) => {
        e.preventDefault();

        //Получаем список ошибок валидации
        let errors = this.validateEmail(this.state.email, []);
        errors = this.validatePhoneNum(errors);
        if(this.state.fio.length === 0)
            errors = [...errors, "ФИО не указано"];
        if(!this.state.confirmation_for_pd)
            errors = [...errors, "Необходимо согласие на обработку персональных данных"];

        this.state.update_error_messages_callback(errors);

        //Если ошибок нет, можно отправлять данные на сервер
        if(errors.length === 0)
        {
            //Устанавливаем анимацию загрузки
            this.setState({ loading: true });

            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                body: JSON.stringify({ "fio": this.state.fio, "phone": this.state.phone, "email": this.state.email })
            };
            fetch('/api/promotionAppointment', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    this.setState({loading: false});

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(!data.result)
                    {
                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                        {
                            localStorage.removeItem('accessToken');
                            window.location = "/login";
                        }
                        else
                            if(this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                    }
                    else
                    {
                        //Сбрасываем ошибки
                        this.state.update_error_messages_callback([]);

                        this.setState({ section: Sections.Summary });
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    this.setState({loading: false});
                    //Устанавливаем ошибку
                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                });

            //Закрываем модальное окно
            this.setState({ open_modal: false });
        }
    }
}

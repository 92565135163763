import styles from './CodeInput.module.css'
import { useState, useEffect, useRef } from 'react';

//Таймаут времени до возможности запроса повторного звонка в секундах
const timeout_to_repeat = 120;

export default function CodeInput({code = "", setCode = (value) => {}, repeat_callback = () => {}, error_message = "", reset_error_callback = () => {}}) {
    //Осталось времени до возможности запроса повторного звонка
    const [leftToRepeat, setLeftToRepeat] = useState(timeout_to_repeat);

    //Ссылка на leftToRepeat и эффект по её обновлению (нужно для корректной работы следующего эффекта)
    const leftToRepeatRef = useRef(leftToRepeat);
    useEffect(() =>{
        leftToRepeatRef.current = leftToRepeat;
    }, [leftToRepeat])

    useEffect(() => {
        //Раз в секунду уменьшаем время до возможности запроса повторного звонка
        setInterval(() => setLeftToRepeat((leftToRepeatRef.current > 0) ? (leftToRepeatRef.current - 1) : leftToRepeatRef.current), 1000);
    }, [])

    const codeHandler = (e) => {
        //Формируем callback для сохранения позиции каретки в случае, если данные не изменились несмотря на ввод пользователя
        const caretStart = e.target.selectionStart;
        const caretEnd = e.target.selectionEnd;
        const save_caret_callback = () => {e.target.setSelectionRange(caretStart, caretEnd)};

        //Проверяем полученные данные на длину и некорректные символы
        if(e.target.value.length > 4)
        {
            //Отрабатываем callback выходим
            save_caret_callback();
            return;
        }
        for(let symbol of e.target.value)
            if(symbol < '0' || symbol > '9')
            {
                //Отрабатываем callback выходим
                save_caret_callback();
                return;
            }

        //Обновляем состояние
        setCode(e.target.value);
        //Сбрасываем ошибку
        reset_error_callback();
    }

    const handleRepeat = (e) => {
        e.preventDefault();

        //Если таймер ещё не истёк, ничего не делаем
        if(leftToRepeat > 0)
            return;

        //Вывываем callback запроса на повторный звонок
        repeat_callback();

        //Сбрасываем таймер
        setLeftToRepeat(timeout_to_repeat);
    }

    //Подсчитываем числа для отображения в разных секциях таймера
    let left_to_repeat_left = Math.floor(leftToRepeat / 60);
    let left_to_repeat_right = leftToRepeat % 60;

    return (
        <div className={styles.formGroup}>
            <div className={styles.formRow}>
                <div className={styles.col}>
                    <input id="code" name="code" className={error_message.length ? [styles.formInput, styles.error].join(' ') : styles.formInput} type="text" placeholder="Введите" value={code}
                           onChange={codeHandler} autoComplete="off"></input>
                    <label htmlFor="code" className={styles.formLabel}>Введите код</label>
                </div>
                <div className={styles.col}>
                    <button type="button" className={((leftToRepeat === 0) ? styles.recall : styles.recallDisabled)} onClick={handleRepeat}>Позвонить еще раз</button>
                </div>
            </div>
            {error_message.length !== 0 &&
            <div className={styles.formError}>{error_message}</div>
            }
            <p className={styles.formGroupDescription}>Повторный звонок через {(left_to_repeat_left < 10) ? ("0" + left_to_repeat_left) : left_to_repeat_left}:{(left_to_repeat_right < 10) ? "0" + (left_to_repeat_right) : left_to_repeat_right}</p>
        </div>
    );
}

import styles from './PasswordInput.module.css'
import { useState, useEffect, useRef } from 'react';

export default function PasswordInput({password = "", setPassword = (value) => {}, label = "Пароль", id = "password", auto_focus = false, error_message = "", reset_error_callback = () => {}}) {
    //Позиция выделения (курсора)
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    //Флаг показа содержимого поля ввода (иначе будут кружки)
    const showPassword = useState(false)[0];
    //Ссылка на поле ввода
    const textInput = useRef(null);

    //Автофокус при появлении компонента, если нужно
    useEffect(() => {
        if(auto_focus && textInput !== null)
            textInput.current.focus();
    }, []);

    return (
        <div className={styles.formGroup} onClick={(e) => { if(textInput !== null) textInput.current.focus() }}>
            <input id={id} name="password" className={error_message.length ? [styles.formInput, styles.error].join(' ') : styles.formInput} type={showPassword ? "text" : "password"}
                   placeholder="Введите" value={password} onChange={(e) => { setPassword(e.target.value); reset_error_callback();}} ref={textInput}
                   onBlur={(e) => { setSelectionStart(e.target.selectionStart); setSelectionEnd(e.target.selectionEnd); }}
                   onFocus={(e) => { setTimeout(() => { textInput.current.setSelectionRange(selectionStart, selectionEnd); }, 1) }} autoComplete="off"></input>
            <label htmlFor={id} className={styles.formLabel}>{label}</label>
            <span className={styles.formClear} onClick={(e) => {setPassword(""); setSelectionStart(0); setSelectionEnd(0); reset_error_callback();}}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 10.0914L10 1.09137M1 1L10 9.99999" stroke="#7E7E7E" strokeWidth="0.909137" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
            {error_message.length !== 0 &&
            <div className={styles.formError}>{error_message}</div>
            }
        </div>
    );
}

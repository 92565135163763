import styles from './Page404.module.css'
import { useEffect } from 'react';
import UnloggedHeader from './../../Headers/UnloggedHeader';
import children_404 from '../../assets/img/404.jpg'

export default function Page404()
{
    useEffect(() => {
        document.title = "Страница не найдена - Здоровый ребенок";
    }, []);

    return (
        <>
            <UnloggedHeader link="/" />
            <main>
                <div className={styles.fullScreen}>
                    <div className={[styles.container, styles.flexRow].join(' ')}>
                        <div className={styles.fullScreenContent}>
                            <p className={styles.notFoundDigit}>
                                404
                            </p>
                            <p className={styles.notFoundTitle}>
                                Страница не найдена
                            </p>
                            <p>Возможно, она была перемещена или неверно указан адрес страницы</p>
                            <a href="/" className={styles.buttonPrimary}>Вернуться на главную страницу</a>
                        </div>
                        <span className={styles.fullScreenImage} style={{backgroundImage: `url(${children_404})`}} ></span>
                    </div>
                </div>
            </main>
        </>
    );
}

import styles from './UnloggedHeader.module.css'
import logo from '../assets/img/logo.png'

export default function UnloggedHeader({link = "/login"}) {
    return (
        <header className={[styles.header, styles.transparent].join(' ')}>
            <div className={[styles.container, styles.flexRow].join(' ')}>
                <a href={link} className={styles.logo}>
                    <img src={logo} alt=""></img>
                </a>
            </div>
        </header>
    );
}

import styles from './PreLoader.module.css'

export default function PreLoader() {
    return (
        <div className={styles.preLoader}>
            <div className={styles.loaderSpinner}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
}

import styles from './Login.module.css'
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import PreLoader from './../../PreLoader';
import UnloggedHeader from './../../Headers/UnloggedHeader';
import PhoneInput from './../../Inputs/PhoneInput';
import PasswordInput from './../../Inputs/PasswordInput';
import Notice from './../../Notice';
import auth_image from '../../assets/img/auth-image.jpg'

export default function Login() {
    //Номер телефона из поля ввода
    const [phone, setPhone] = useState("");
    //Ошибка для вывода в форме ввода номера телефона
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
    //Пароль из поля ввода
    const [password, setPassword] = useState("");
    //Ошибка для вывода в форме ввода пароля
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    //Список ошибок для вывода в модальном окне
    const [errorMessages, setErrorMessages] = useState([]);
    //Флаг загрузки данных (необходимости показа прелоадера)
    const [loading, setLoading] = useState(false);

    //Объекты роутера
    const location = useLocation();

    /* eslint-disable */
    useEffect(() => {
        document.title = "Вход - Здоровый ребенок";

        //Запрашиваем статус
        if(localStorage.getItem('accessToken'))
        {
            //Включаем анимацию загрузки
            setLoading(true);

            fetch('/api/getcabuserinfo', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //Если пользователь уже авторизован, перенаправляем его на главную
                    if(data.result)
                        window.location = "/";
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }, []);
    /* eslint-enable */

    const validatePassword = (password) => {
        //Проверяем пароль на длину
        if(password.length < 6)
        {
            setPasswordErrorMessage("Пароль содержит менее 6 символов");
            return false;
        }

        return true;
    }

    const validatePhoneNum = (phone) => {
        //Проверяем номер телефона на длину
        if(phone.length !== 10)
        {
            setPhoneErrorMessage("Неверная длина номера телефона");
            return false;
        }

        //Проверяем номер телефона на наличие нечисловых символов
        for(let symbol of phone)
        {
            if(symbol < '0' || symbol > '9')
            {
                setPhoneErrorMessage("Номер телефона содержит неопознанные символы");
                return false;
            }
        }

        return true;
    }

    const loginSubmitHandler = (e) => {
        //Отключаем дефолтное поведение кнопки
        e.preventDefault();

        //Проводим валидацию
        let data_ok = true;
        if(!validatePhoneNum(phone))
            data_ok = false;
        if(!validatePassword(password))
            data_ok = false;

        //Если ошибок нет, можно отправлять данные на сервер
        if(data_ok)
        {
            //Включаем анимацию загрузки
            setLoading(true);

            //Отправляем данные на сервер
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "userLogin": phone, "userPassword": password})
            };
            fetch('/api/login', request)
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                    {
                        //Устанавливаем ошибку и прекращаем обработку
                        if(errorMessages.indexOf("Получен некорректный ответ от сервера") === -1)
                            setErrorMessages([...errorMessages, "Получен некорректный ответ от сервера"]);
                        return;
                    }

                    //В случае ошибки добавляем ее в состояние
                    if(data.result === false)
                    {
                        if(errorMessages.indexOf(data.body.errorAns) === -1)
                            setErrorMessages([...errorMessages, data.body.errorAns]);
                    }
                    else
                    {
                        if(!data.body.accessToken)
                        {
                            if(errorMessages.indexOf("Неверная пара логин-пароль") === -1)
                                setErrorMessages([...errorMessages, "Неверная пара логин-пароль"]);
                        }
                        else
                        {
                            //Сохраняем полученный от сервера токен
                            localStorage.setItem('accessToken', data.body.accessToken);

                            //Парсим параметры адресной строки
                            const query = new URLSearchParams(location.search);

                            //Если был указан параметр from, перенаправляем пользователя на указанную страницу
                            if(query.get('from') !== null)
                                window.location = query.get('from');
                            else
                            //Иначе - перенаправляем на главную
                            window.location = "/";
                        }
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки
                    setLoading(false);

                    //Устанавливаем ошибку
                    if(errorMessages.indexOf("Ошибка получения ответа от сервера") === -1)
                        setErrorMessages([...errorMessages, "Ошибка получения ответа от сервера"]);
                });
        }
    }

    return (
        <>
            {loading && <PreLoader />}
            <UnloggedHeader link="/login" />
            <main>
                <div className={styles.fullScreen}>
                    <div className={[styles.container, styles.flexRow].join(' ')}>
                        <div className={styles.fullScreenContent}>
                            <form className={styles.authForm}>
                                <p className={styles.formTitle}>
                                    Вход в личный кабинет
                                </p>
                                <PhoneInput phone={phone} setPhone={setPhone} error_message={phoneErrorMessage} reset_error_callback={ () => setPhoneErrorMessage("") }
                                    auto_focus={true}/>
                                <PasswordInput password={password} setPassword={setPassword} label="Пароль" error_message={passwordErrorMessage}
                                               reset_error_callback={ () => setPasswordErrorMessage("") } auto_focus={false}/>
                                <input type="submit" className={styles.buttonPrimary} value="Войти" onClick={loginSubmitHandler}></input>
                            </form>
                            <p>
                                Забыли пароль? Перейдите на страницу<br/> <a href="/restore">восстановления доступа</a>
                            </p>
                            <p>
                                Если Вы никогда не пользовались личным<br/>кабинетом, то <a href="/register">зарегистрируйтесь</a>
                            </p>
                        </div>
                        <span className={styles.fullScreenImage} style={{backgroundImage: `url(${auth_image})`}} ></span>
                    </div>
                </div>
            </main>
            <Notice messages={errorMessages} reset_messages_callback={() => setErrorMessages([])} />
        </>
    );
}

import React from 'react';
import '../assets/css/main.css';
import '../assets/css/nice-select2.css';
import '../assets/css/swiper-bundle.min.css';
import {Notice, initForms, initLayoutScripts} from '../assets/js/notice.js'
import article_logo from '../assets/img/article.png'
import univers_chek_logo from '../assets/img/univers-chek.png'
import zhen_chek_logo from '../assets/img/zhen-chek.png'
import vpch_logo from '../assets/img/VPCH-edited.png'
import biohim_logo from '../assets/img/biohim.png'
import cardio_logo from '../assets/img/cardio-edited.png'
import review_logo from '../assets/img/leave-review-edited.png'
import pediatrician_logo from '../assets/img/pediatrician-unlimit-edited.png'
import flebo_logo from '../assets/img/flebo-edited.png'

let was_inited = false;

export default class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = { loading: false, events: [], tests: [], visits: [], future_visits: [], error_messages: props.error_messages,
                        update_error_messages_callback: props.update_error_messages_callback,  family_data: props.family_data, need_to_init: false, business_units_uids: [],
                        cancel_reasons: [], selected_reason: -1, cancel_rqid: "" };

        //Если пользователь не авторизован, перенаправляем его на страницу входа
        if(!localStorage.getItem('accessToken'))
            window.location = "/login";
    }

    componentDidUpdate(prevProps) {
        //Если есть ошибки, выводим всплывающее окно с ними
        if(this.state.error_messages.length > 0)
            Notice.show(this.state.error_messages.join('<br/>'));

        if(prevProps.error_messages !== this.props.error_messages)
            this.setState({error_messages: this.props.error_messages});
        if(prevProps.update_error_messages_callback !== this.props.update_error_messages_callback)
            this.setState({update_error_messages_callback: this.props.update_error_messages_callback});
        if(prevProps.family_data !== this.props.family_data)
            this.setState({family_data: this.props.family_data});

        if(this.state.need_to_init)
        {
            //Инициализируем скрипты от верстальщиков
            if(was_inited)
                initLayoutScripts();
            was_inited = true;
            initLayoutScripts();
            Notice.setHideCallback(() => { this.setState({error_messages: []}); this.state.update_error_messages_callback([]); });
            this.setState({need_to_init: false});
        }
    }

    componentDidMount() {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Счетчик обработанных запросов
        const requests_to_fetch = 5;
        let requests_processed = 0;

        //Запрашиваем события для карусели
        let events = [];
        fetch('/api/get-slider?code=1000001', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Парсим данные
                events = data;
                this.setState({ events: data });

                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем список анализов
        fetch('/api/GetClAnalysFullList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ tests: [].concat(...Object.values(data.body)) });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем прошедшие посещения
        fetch('/api/GetClVisitFullList', {method: 'POST', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                {
                    //Перебираем поля объекта
                    let visits = [];
                    for(let property in data.body)
                    {
                        //Перебираем поля вложенного объекта
                        for(let row of data.body[property])
                        {
                            //Сохраняем запись в список, перенося uid члена семьи внутрь
                            row.cluid = property;
                            visits.push(row);
                        }
                    }

                    this.setState({ visits: visits });
                }
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем будущие посещения
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({})
        };
        fetch('/api/GetClSched', request)
            .then(response => response.json())
            .then(data => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                {
                    //Перебираем поля объекта
                    let future_visits = [];
                    for(let property in data.body)
                    {
                        //Перебираем поля вложенного объекта
                        for(let row of data.body[property])
                        {
                            //Сохраняем запись в список, перенося uid члена семьи внутрь
                            row.cluid = property;
                            future_visits.push(row);
                        }
                    }

                    this.setState({ future_visits: future_visits });
                }
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
        //Запрашиваем соответствия uid - название бизнес-подразделения
        fetch('/api/GetBuList', { method: 'GET', headers: { 'ZR-Access-Token': localStorage.getItem('accessToken') } })
            .then(response => response.json())
            .then(data => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                    this.setState({ business_units_uids: data.body });
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    get_events_images = (events_original) => {
        //Если event`ов нет, выходим
        if(events_original.length === 0)
        {
            this.setState({loading: false, need_to_init: true});
            return;
        }

        let events = events_original;
        let events_quantity = events_original.length;
        let events_processed = 0;
        for(let even_t of events)
        {
            //Запрашиваем аватар
            fetch('/api/GetImageById', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                body: JSON.stringify({ "imgId": even_t.file_id })})
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки, если все запросы обработаны
                    if(++events_processed >= events_quantity)
                        this.setState({loading: false, need_to_init: true});

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                        return;
                    else
                    {
                        //В случае ошибки добавляем ее в состояние
                        if(!data.result)
                        {
                            //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                            if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                            {
                                localStorage.removeItem('accessToken');
                                window.location = "/login";
                            }
                        }
                        else
                        {
                            if(!('base64data' in data.body))
                                return;

                            //Парсим данные
                            even_t.bnr_img = data.body.base64data;
                        }
                    }

                    //Сохраняем результат, если все запросы обработаны
                    if(events_processed >= events_quantity)
                        this.setState({events: events});
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки, если все запросы обработаны
                    if(++events_processed >= events_quantity)
                        this.setState({loading: false, need_to_init: true});
                });
        }
    }

    changeDateFormat = (date) => {
        if(date.length < 10)
            return date;
        let new_date = date[8] + date[9] + '.' + date[5] + date[6] + '.' + date[0] + date[1] + date[2] + date[3] + date.substring(10, date.length);
        return new_date;
    }

    render() {
        return (
            <>
                {this.state.loading &&
                <div class="pre-loader">
                    <div class="loader-spinner">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                }

                {this.state.events.length !== 0 &&
                <div class="articles">
                    <p class="block-title">
                        ДЛЯ ВАС
                    </p>
                    <div class="swiper">
                        <div class="swiper-wrapper">
                            {this.state.events.map((data, index) =>
                                <div class="swiper-slide" key={index}>
                                    <a href={data.bnr_href} class="articles--item" target="_blank">
                                        <img style={{backgroundImage: `url(data:image/jpeg;base64,${data.bnr_img})`}} class="articles--item__image"></img>
                                        <p class="articles--item__title">
                                            {data.bnr_name}
                                        </p>
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="navigation-item navigation-prev">
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                            <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                        </svg>
                    </div>
                    <div class="navigation-item navigation-next">
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                            <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                        </svg>
                    </div>
                </div>}
                <div class="row">
                    <div class="split">
                        <p class="block-title">
                            Мои анализы
                        </p>
                        <a class="split--link text-right" href="/tests">
                            cмотреть все анализы
                        </a>
                    </div>
                    <div class="row-list">
                        {this.state.tests.filter((data) => data.status === "10").filter((data, index) => index <= 2).map((data, index) =>
                            <div class="row-list--item analysis finish" key={index}>
                                <p class="date">
                                    {this.changeDateFormat(data.date.split(" ")[0])}
                                </p>
                                <p class="title">
                                    <b>{data.name}</b>
                                </p>
                                <p class="pacient">
                                    {data.cifio}
                                </p>
                                <a href="#" class="status finish" data-auid={data.аuid} onClick={this.openTestDoc}>
                                    Результат
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.714 6.14807L14.259 0.363037C14.07 0.164037 13.803 0 13.529 0H1.979C0.874004 0 0 1 0 2.104V9.104C0 9.656 0.437014 10 0.989014 10H0.993988C1.54699 10 2 9.656 2 9.104V3.104C2 2.552 2.427 2 2.979 2H12V6.104C12 7.209 12.874 8 13.979 8H18V9.104C18 9.656 18.437 10 18.989 10H18.994C19.547 10 20 9.656 20 9.104V6.83704C20 6.58004 19.891 6.33407 19.714 6.14807ZM3.979 15.104C3.979 14.552 3.531 14.104 2.979 14.104H1.979V16.104H2.979C3.531 16.104 3.979 15.656 3.979 15.104ZM5.97501 14.838C6.06501 16.568 4.689 18 2.979 18H2V19.104C2 19.656 1.54699 20 0.993988 20H0.989014C0.437014 20 0 19.656 0 19.104V13.104C0 12.552 0.427004 12 0.979004 12H2.80899C4.43399 12 5.89001 13.216 5.97501 14.838ZM11 15.104C11 14.552 10.531 14 9.979 14H9V18H9.979C10.531 18 11 17.656 11 17.104V15.104ZM13 15V17C13 18.65 11.65 20 10 20H7.89499C7.39999 20 7 19.599 7 19.104V12.979C7 12.438 7.438 12 7.979 12H10C11.65 12 13 13.35 13 15ZM20 13.026V13.052C20 13.604 19.531 14 18.979 14H16V16H18.979C19.531 16 20 16.473 20 17.026V17.052C20 17.604 19.531 18 18.979 18H16V19.104C16 19.656 15.547 20 14.994 20H14.989C14.437 20 14 19.656 14 19.104V13.104C14 12.552 14.427 12 14.979 12H18.979C19.531 12 20 12.473 20 13.026Z" fill="#D30D15"/>
                                    </svg>
                                </a>
                            </div>)}
                        {this.state.tests.filter((data) => data.status === "10").length === 0 && <div>Анализы отсутствуют</div>}
                    </div>
                </div>
                <div class="row">
                    <div class="split">
                        <p class="block-title">
                            ПРЕДСТОЯЩИЕ ЗАПИСИ
                        </p>
                        <a class="split--link text-right" href="/visits">
                            СМОТРЕТЬ ВСЕ ЗАПИСИ
                        </a>
                    </div>
                    <div class="row-list">
                        {this.state.future_visits.sort((a, b) => a.date > b.date).filter((data, index) => index <= 2).map((data, index) =>
                            <div class="row-list--item reception" key={index}>
                                <p class="date">
                                    {this.changeDateFormat(data.date.split(" ")[0])} {data.date.split(" ")[1].substr(0, data.date.split(" ")[1].length - 3)}
                                </p>
                                <p class="title">
                                    <b>{data.name}</b>
                                </p>
                                <p class="doctor">
                                    {data.empfio}
                                </p>
                                <p class="address">
                                    {(this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid).length !== 0) && this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid)[0].bu_name}
                                    {(this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid).length === 0) && "Адрес не указан"}
                                </p>
                                <p class="pacient">
                                    {this.state.family_data.filter((family_data) => family_data.uid == data.cluid).length ? this.state.family_data.filter((family_data) => family_data.uid == data.cluid)[0].name : ""}
                                </p>
                                <button class="cancel" data-rqid={data.rqid} onClick={this.cancelAppointment}>Отменить запись</button>
                            </div>)}
                        {this.state.future_visits.length === 0 && <div>Записи отсутствуют</div>}
                    </div>
                </div>
                <div class="row">
                    <div class="split">
                        <p class="block-title">
                            МОЯ ИСТОРИЯ
                        </p>
                        <a class="split--link text-right" href="/visits">
                            СМОТРЕТЬ ВСЮ ИСТОРИЮ
                        </a>
                    </div>
                    <div class="row-list">
                        {this.state.visits.sort((a, b) => a.date > b.date).filter((data, index) => index <= 2).map((data, index) =>

                            <div class="row-list--item reception finish" key={index}>
                                <p class="date">
                                    {data.date && this.changeDateFormat(data.date.split(" ")[0])}
                                </p>
                                <p class="title">
                                    <b>{data.name}</b>
                                </p>
                                <p class="doctor">
                                    {data.empfio}
                                </p>
                                <p class="address">
                                    {(this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid).length !== 0) && this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid)[0].bu_name}
                                    {(this.state.business_units_uids.filter((bu_data) => data.buid != null && bu_data.onec_uid == data.buid).length === 0) && "Адрес не указан"}
                                </p>
                                <p class="pacient">
                                    {this.state.family_data.filter((family_data) => family_data.uid == data.cluid).length ? this.state.family_data.filter((family_data) => family_data.uid == data.cluid)[0].name : ""}
                                </p>
                                {data.document_url &&
                                    <a href="#" class="status finish" data-url={data.document_url} onClick={this.openTestDoc}>
                                    Заключение
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.714 6.14807L14.259 0.363037C14.07 0.164037 13.803 0 13.529 0H1.979C0.874004 0 0 1 0 2.104V9.104C0 9.656 0.437014 10 0.989014 10H0.993988C1.54699 10 2 9.656 2 9.104V3.104C2 2.552 2.427 2 2.979 2H12V6.104C12 7.209 12.874 8 13.979 8H18V9.104C18 9.656 18.437 10 18.989 10H18.994C19.547 10 20 9.656 20 9.104V6.83704C20 6.58004 19.891 6.33407 19.714 6.14807ZM3.979 15.104C3.979 14.552 3.531 14.104 2.979 14.104H1.979V16.104H2.979C3.531 16.104 3.979 15.656 3.979 15.104ZM5.97501 14.838C6.06501 16.568 4.689 18 2.979 18H2V19.104C2 19.656 1.54699 20 0.993988 20H0.989014C0.437014 20 0 19.656 0 19.104V13.104C0 12.552 0.427004 12 0.979004 12H2.80899C4.43399 12 5.89001 13.216 5.97501 14.838ZM11 15.104C11 14.552 10.531 14 9.979 14H9V18H9.979C10.531 18 11 17.656 11 17.104V15.104ZM13 15V17C13 18.65 11.65 20 10 20H7.89499C7.39999 20 7 19.599 7 19.104V12.979C7 12.438 7.438 12 7.979 12H10C11.65 12 13 13.35 13 15ZM20 13.026V13.052C20 13.604 19.531 14 18.979 14H16V16H18.979C19.531 16 20 16.473 20 17.026V17.052C20 17.604 19.531 18 18.979 18H16V19.104C16 19.656 15.547 20 14.994 20H14.989C14.437 20 14 19.656 14 19.104V13.104C14 12.552 14.427 12 14.979 12H18.979C19.531 12 20 12.473 20 13.026Z" fill="#D30D15"/>
                                    </svg>
                                </a>
                                }
                            </div>)}
                        {this.state.visits.length === 0 && <div>Посещения отсутствуют</div>}
                    </div>
                </div>
                <div id="cancel_modal" class="modal">
                    <div class="modal-content">
                        <div class="modal-content--close">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <p class="modal-content--title">
                            Отмена записи
                        </p>
                        <div>
                        <p>Пожалуйста, выберите причину отмены:</p>
                            <div class="onec-variants">
                                {this.state.cancel_reasons.map((data, index) =>
                                    <div key={index}>
                                        <input id={"reason" + index} name={"reason" + index} type="radio" checked={this.state.selected_reason == index} onChange={(e) => this.setState({ selected_reason: index })}></input>
                                        <label htmlFor={"reason" + index}>{data.evсname}</label>
                                    </div>
                                )}
                            </div>
                            <button type="button" class="button-primary button-onec" onClick={this.confirmCancel}>Подтвердить</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    base64toBlob = (base64Data, contentType) => {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    openTestDoc = (e) => {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Запрашиваем указанный документ
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({ "uid": e.currentTarget.dataset.auid, "ftype": "1" })
        };
        fetch('/api/getfile/', request)
            .then(response => response.json())
            .then(data => {
                //Сбрасываем анимацию загрузки
                this.setState({ loading: false });

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                if(data.result && data.body.binaryData && data.body.ext)
                {
                    //Переводим base64 в blob
                    let blob = this.base64toBlob(data.body.binaryData, "application/" + data.body.ext);

                    //Получаем url
                    let doc_url = URL.createObjectURL(blob);

                    //Скачаваем файл через создание элемента 'a'
                    let file_link = document.createElement('a');
                    file_link.href = doc_url;
                    file_link.download = doc_url.split("/").pop() + "." + data.body.ext;
                    file_link.click();
                }
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    cancelAppointment = (e) => {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Сохраняем rqid для дальнейшей обработки
        let rqid = e.currentTarget.dataset.rqid;

        //Запрашиваем список причин отмены
        fetch('/api/GetEvcList', {method: 'GET', headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') }})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(data.result === false)
                {
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
                    else
                    {
                        if(this.state.error_messages.indexOf(data.body.errorAns) === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                    }
                }
                else
                {
                    //Сохраняем данные
                    let cancel_reasons = [];
                    for(let reason of data.body)
                        if(reason.isGroup === false)
                            cancel_reasons.push(reason);
                    this.setState({cancel_reasons: cancel_reasons, selected_reason: -1, cancel_rqid: rqid});

                    //Открываем модальное окно
                    const form = document.getElementById("cancel_modal")
                    if(form) {
                        form.classList.add('open')
                    }
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    confirmCancel = (e) => {
        e.preventDefault();

        //Убеждаемся, что причина отмены выбрана
        if(this.state.selected_reason === -1)
        {
            //Устанавливаем ошибку и выходим
            if(this.state.error_messages.indexOf("Не выбрана причина отмены") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Не выбрана причина отмены"]);
            return
        }

        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Запрашиваем отмену записи
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({"rqid": this.state.cancel_rqid, "evсid": this.state.cancel_reasons[this.state.selected_reason].evсid})
        };
        fetch('/api/CancelRequest', request)
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(data.result === false)
                {
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
                    else
                        if(this.state.error_messages.indexOf(data.body.errorAns) === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                }
                //Иначе - переспрашиваем список будущих посещений
                else
                {
                    //Закрываем модальное окно
                    const form = document.getElementById("cancel_modal")
                    if(form) {
                        form.classList.remove('open');
                    }

                    //Включаем анимацию загрузки
                    this.setState({loading: true});

                    //Запрашиваем будущие посещения
                    const request = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                        body: JSON.stringify({})
                    };
                    fetch('/api/GetClSched', request)
                        .then(response => response.json())
                        .then(data => {
                            //Выключаем анимацию загрузки
                            this.setState({loading: false});

                            //Проверяем наличие обязательных полей в ответе
                            if(!('result' in data) || !('body' in data))
                            {
                                //Устанавливаем ошибку и прекращаем обработку
                                if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                    this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                return;
                            }

                            //Парсим данные
                            if(data.result)
                            {
                                //Перебираем поля объекта
                                let future_visits = [];
                                for(let property in data.body)
                                {
                                    //Перебираем поля вложенного объекта
                                    for(let row of data.body[property])
                                    {
                                        //Сохраняем запись в список, перенося uid члена семьи внутрь
                                        row.cluid = property;
                                        future_visits.push(row);
                                    }
                                }

                                this.setState({ future_visits: future_visits });
                            }
                            else
                                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                {
                                    localStorage.removeItem('accessToken');
                                    window.location = "/login";
                                }
                        })
                        .catch((error) => {
                            //Выключаем анимацию загрузки
                            this.setState({loading: false});
                            //Устанавливаем ошибку
                            if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                        });
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }
}

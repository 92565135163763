import styles from './CheckboxInput.module.css'

export default function CheckboxInput({flag = false, setFlag = (value) => {}, label = "Флаг", id = "checkbox", bottom_text = ""}) {
    return (
        <div className={styles.formCheckbox}>
            <input id={id} name={id} type="checkbox" onChange={ (e) => setFlag(!flag) } checked={flag}></input>
            <label htmlFor={id} className={styles.formCheckboxLabel}>{label}</label>
            {bottom_text !== "" &&
                <div className={styles.bottomText}>{bottom_text}</div>
            }
        </div>
    );
}

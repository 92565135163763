import styles from './Notice.module.css'

export default function Notice({messages = [], reset_messages_callback = () => {}}) {
    return (
        <>
        <div className={messages.length !== 0 ? [styles.overlay, styles.show].join(' ') : styles.overlay} onClick={reset_messages_callback}></div>
        {messages.length !== 0 &&
        <div className={[styles.notice, styles.show].join(' ')}>
            <span className={styles.noticeClose} onClick={reset_messages_callback}>
                <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.33105 10.0914L10.6045 1.09137M1.33105 1L10.6045 9.99999" stroke="#D30D15" strokeWidth="0.909137" strokeLinecap="round" strokeLinejoin="round"></path>
                </svg>
            </span>
            {messages.map((message, index) => <div key={index}>{message}</div>)}
        </div>
        }
        </>
    );
}
